
import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const cards = [
  {
    title: "Upgrades",
    description: "Our Infor ERP experts will seamlessly upgrade the software by following a streamlined process that minimizes disruptions while ensuring full technical compliance and enhanced functionality.",
    gradient: "linear-gradient(135deg, #8B5CF6 0%, #D946EF 100%)"
  },
  {
    title: "Migration",
    description: "Our Infor ERP experts will facilitate a seamless migration by employing advanced ETL processes to extract, transform, and load your legacy data into Infor ERP with full data integrity and compliance.",
     gradient: "linear-gradient(135deg, #DC2626 0%, #F97316 100%)"
  },
  {
    title: "Customization",
    description: "We can customize Infor ERP software to meet your business needs by utilizing the Infor Configuration Console, allowing for modifications to fields, forms, workflows, and business rules without extensive coding.",
    gradient: "linear-gradient(135deg, #2563EB 0%, #06B6D4 100%)"
  },
  {
    title: "Consultation",
    description: "Our ERP experts will deliver tailored consultation by performing a detailed needs assessment and gap analysis to align Infor ERP capabilities with your business requirements.",
    
     gradient: "linear-gradient(135deg, #6366F1 0%, #4F46E5 100%)"
  },
  {
    title: "Managed Services",
    description: "Our Infor experts are available 24/7 to solve technical issues and provide functional insights. We ensure seamless integration and optimization across all your enterprise applications.",
    gradient: "linear-gradient(135deg, #BE185D 0%, #EC4899 100%)"
  },
  {
    title: "Training",
    description: "We offer comprehensive training for your internal IT teams and end-users to ensure smooth adoption and successful implementation of your enterprise applications.",
    gradient: "linear-gradient(135deg, #D97706 0%, #EAB308 100%)"
  }
];

const ServiceCard = ({ title, description, gradient }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="group relative">
      <div
        style={{
          background: gradient,
          boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
          transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1)',
        }}
        className="rounded-lg p-6 overflow-hidden relative cursor-pointer"
        onClick={toggleExpand}
      >
        {/* Content container */}
        <div className="relative z-10 flex justify-between items-center">
          <h3
            style={{
              transition: 'transform 0.8s cubic-bezier(0.4, 0, 0.2, 1)'
            }}
            className="font-bold text-xl text-white mb-6"
          >
            {title}
          </h3>
          {/* Toggle Icon */}
          {isExpanded ? (
            <FaMinus className="text-white" />
          ) : (
            <FaPlus className="text-white" />
          )}
        </div>
        
        {/* Description with fade-in and slide-up animation */}
        <div className={`overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? 'max-h-[200px]' : 'max-h-0'}`}>
          <p
            className={`text-white/90 transition-opacity duration-500 ease-in-out ${isExpanded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}
            style={{
              transitionDelay: '0.3s',
              lineHeight: '1.6'
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

const InfoServices = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <style jsx global>{`
        @keyframes ripple {
          0% {
            transform: scale(0);
            opacity: 0.7;
          }
          100% {
            transform: scale(3);
            opacity: 0;
          }
        }
        .animate-ripple {
          animation: ripple 2.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }
      `}</style>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {cards.map((card, index) => (
          <ServiceCard
            key={index}
            title={card.title}
            description={card.description}
            gradient={card.gradient}
          />
        ))}
      </div>
    </div>
  );
};

export default InfoServices;
