
import { useScrollAnimation } from "../../../utils/Utils";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";

import sapbanner from '../../../Assets/SAP-page/SAPV.jpg'
import SAPCards from './SAPCards'
import sap1 from '../../../Assets/SAP-page/sap1.jpg'
import sap2 from '../../../Assets/SAP-page/sap2.jpg'
import saplogo from '../../../Assets/SAP-page/sap.png'
import TitleUpdater from "../../../utils/TitleUpdater";

const SAP = () => {
  useScrollAnimation();
  
  
  return (
    <div>
      <TitleUpdater title="Kapil Tech SAP Staffing Services " />
      {/* Header */}
        
     <div className="m-auto">
  <img src={sapbanner} alt="" className="w-full h-auto max-w-full mb-2" />
</div>

     
      <HeaderSectionReusableComponent
        mainHeading={
          "Tailored SAP Consulting Services for Improved Efficiency"
        }
        heading={
          "Optimize Your SAP System for Maximum Efficiency and Seamless Operations"
        }
        paragragh={
          <>
            At Kapil Tech, we offer tailored SAP consulting services to improve efficiency and streamline operations in your manufacturing business. Our experts will work closely with you to optimize your SAP system, ensuring maximum efficiency and meeting your specific needs.
          </>
        }
        imgScr={sap1}
      />
      {/*  Break Free from the Status Quo and Embrace  */}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          "Unlock Your Business Potential with Expert SAP Solutions"
        }
        paragraph={
          <>
            <div>
               <span className="text-red text-lg font-semibold ">Tailored Support</span>  <br />
Our SAP consulting services offer tailored support to ensure your system operates at its best.
         </div>

            <div>
               <span  className="text-red text-lg font-semibold ">Improved ROI</span> <br />
By leveraging our SAP consulting services, you can achieve improved ROI and business outcomes.
     </div>
          </>
        }
        imgsrc={sap2}
      />
        
      {/* Top-notch SAP Advisory Services That Differentiate Your Enterprise from the Rest*/}

        
         <div className="flex justify-center items-center">
        <img src={saplogo} alt="sapLogo" className="h-20"/>
        <span className="text-xl font-semibold text-bluecolor">Offerings</span>
        
      </div>
      <div style={{ background: "#006BB8" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
      
      <SAPCards/>
  
    </div>
  );
};

export default SAP;
