import React, { useState,useRef } from "react";
import {  Briefcase, MapPin, Clock, Building2, Target, CheckCircle2} from "lucide-react";
import { useLocation } from "react-router-dom";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import axios from "axios";
import { carrerList } from "./CareerList";

const Description = () => {
  const location = useLocation();
  const jobdes = location.state.job || "null";
 const fileInputRef = useRef(null); 

    const [careerApply, setCareerApply] = useState({
    fullname: "",
    email: "",
    mobilenumber: "",
    applyto: "",
    experience: "",
    previoussalary: "",
    exceptedsalary: "",
    noticeperiod: "",
    location: "",
    willingToRelocate: "",
    resume: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCareerApply({ ...careerApply, [name]: value });
    // Clear error when field is modified
    if (value && errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Validate Full Name
    if (!careerApply.fullname) {
      formErrors.fullname = "Full Name is required";
      isValid = false;
    }

    // Validate Email
    if (!careerApply.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(careerApply.email)) {
      formErrors.email = "Email is invalid";
      isValid = false;
    }

    // Validate Mobile Number
    if (!careerApply.mobilenumber) {
      formErrors.mobilenumber = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(careerApply.mobilenumber)) {
      formErrors.mobilenumber = "Mobile Number must contain only digits";
      isValid = false;
    } else if (careerApply.mobilenumber.length !== 10) {
      formErrors.mobilenumber = "Mobile Number must be exactly 10 digits";
      isValid = false;
    }

    // Validate all other required fields
    const requiredFields = [
      'applyto',
      'experience',
      'previoussalary',
      'exceptedsalary',
      'noticeperiod',
      'location',
      'willingToRelocate'
    ];

    requiredFields.forEach(field => {
      if (!careerApply[field]) {
        formErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        isValid = false;
      }
    });

    // Validate Resume
    if (!careerApply.resume) {
      formErrors.resume = "Resume is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };


  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("before sending data with post method",careerApply)
    if (validateForm()) {
       toast.info("Thank you for submitting your application...");
      const formData = new FormData();
      Object.keys(careerApply).forEach(key => {
        formData.append(key, careerApply[key]);
      });

      try {
        const response = await axios.post('https://kapil-api-backend.onrender.com/api/carrerpage/submit-form', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        toast.success("Application submitted successfully!");
        console.log("Response:", response.data);
        
        // Reset form after successful submission
        setCareerApply({
          fullname: "",
          email: "",
          mobilenumber: "",
          applyto: "",
          experience: "",
          previoussalary: "",
          exceptedsalary: "",
          noticeperiod: "",
          location: "",
          willingToRelocate: "",
          resume: "",
        });

          // Clear the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear the file input
        }
        
      } catch (error) {
        toast.error("Failed to submit the form. Please try again.");
        console.error("Error:", error);
      }
    } else {
      toast.warn("Please fill in all required fields correctly.");
    }
  };


  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setCareerApply({ ...careerApply, resume: file });
        // Clear any existing resume error
        if (errors.resume) {
          setErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors.resume;
            return newErrors;
          });
        }
      } else {
        setErrors(prev => ({
          ...prev,
          resume: "Please upload a valid PDF file."
        }));
      }
    }
  };
  return (
    <div className="min-h-screen bg-gray-50">
      {/* <div className="relative h-[400px] overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-90"></div>
        <img
          src="/api/placeholder/1920/400"
          alt="Career Banner"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white space-y-4">
            <h1 className="text-5xl font-bold">Join Our Team</h1>
            <p className="text-xl opacity-90">Shape the future with us</p>
          </div>
        </div>
      </div> */}
       <div className="w-full max-w-full h-auto m-auto ">
      <video 
        src="https://ik.imagekit.io/hjiuirrf7/KapilLogoRevealBanner.mp4?tr=orig&updatedAt=1732865386212" 
        className="w-full h-auto " 
        preload="metadata" 
        autoPlay 
        muted 
        playsInline
      >
        Your browser does not support the video tag.
      </video>
    </div>

      <div className="max-w-7xl mx-auto px-2 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Job Details Section */}
          <div className="lg:col-span-1 bg-white rounded-lg p-6 shadow-sm">
            <div className="space-y-1">
              <div>
                <h2 className="text-3xl font-bold text-gray-900 mb-3 text-[#000080]">{jobdes.position}</h2>
                <div className="flex flex-wrap gap-2">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
                    <Clock className="w-4 h-4 mr-1 text-[#4169E1]" />
                    Full Time
                  </span>
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-50 text-green-700">
                    <MapPin className="w-4 h-4 mr-1 text-[#4169E1]" />
                    {jobdes.Mode}
                  </span>
                </div>
              </div>

              <div className="grid gap-2">
                {/* Job details grid items */}
                <div className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg">
                  <Briefcase className="w-5 h-5 text-blue-600 text-[#4169E1]" />
                  <div>
                    <p className="font-medium text-gray-700">Experience</p>
                    <p className="text-gray-600">{jobdes.experience}</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg">
                  <Building2 className="w-5 h-5 text-blue-600 text-[#4169E1]" />
                  <div>
                    <p className="font-medium text-gray-700">Work Mode</p>
                    <p className="text-gray-600">{jobdes.Mode}</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg">
                  <Target className="w-5 h-5 text-blue-600 text-[#4169E1]" />
                  <div>
                    <p className="font-medium text-gray-700 text-[#1E3A8A]">Job Type</p>
                    <p className="text-gray-600">{jobdes.jobType}</p>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-900 text-[#1E3A8A]">Requirements</h3>
                <ul className="space-y-2">
                  {jobdes.requirements.map((requirement, index) => (
                    <li key={index} className="flex items-start space-x-3">
                      <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0 mt-1 text-[#4169E1]" />
                      <span className="text-gray-600">{requirement}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-900 text-[#1E3A8A]">Job Description</h3>
                <p className="text-gray-600 leading-relaxed">{jobdes.description}</p>
              </div>
            </div>
          </div>

          {/* Application Form */}
          <div className="lg:col-span-2 bg-white rounded-lg p-6 shadow-sm">
            <h2 className="text-2xl font-bold text-gray-900 mb-6 text-[#000080]">Apply Now</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Form fields remain the same, just removed Card wrapper */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Form inputs remain the same */}
               
                {/* Rest of the form fields remain the same */}
                {/* ... */}
                <div className="w-full mb-3">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
                <input
                  type="text"
                  name="fullname"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Full Name"
                  value={careerApply.fullname}
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.fullname && (
                    <span className="text-xs text-red ">{errors.fullname}</span>
                  )}
                </div>
              </div>
                <div className="w-full mb-3">
                   <label className="block text-sm font-medium text-gray-700 mb-1">Mobile Number</label>
                <input
                  type="number"
                  name="mobilenumber"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Mobile"
                  value={careerApply.mobilenumber}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.mobilenumber && (
                    <span className="text-xs text-red ">
                      {errors.mobilenumber}
                    </span>
                  )}
                </div>
              </div>
                <div className="w-full mb-3">
                   <label className="block text-sm font-medium text-gray-700 mb-1">Email Id</label>
                <input
                  type="email"
                  name="email"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Email"
                  value={careerApply.email}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.email && (
                    <span className="text-xs text-red ">{errors.email}</span>
                  )}
                </div>
              </div>
              {/* Applied To */}
                <div className="w-full mb-3">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Postion Applied to</label>
                <select
                  name="applyto"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.applyto}
                  onChange={handleChange}
                  >
                    
                  <option  value="" disabled >
                    Applied to
                    </option>
                    {carrerList.map((ele,index) => (
                      <option key={index}>{ele.position}</option>
                    ))}
                </select>

                <div className="h-3">
                  {errors.applyto && (
                    <span className="text-xs text-red ">{errors.applyto}</span>
                  )}
                </div>
              </div>

              {/* Experience */}
                <div className="w-full mb-4">
                   <label className="block text-sm font-medium text-gray-700 mb-1">Experience</label>
                <select
                  name="experience"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.experience}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Experience (Years)
                  </option>
                  <option>0-1</option>
                  <option>1-2</option>
                  <option>2-3</option>
                  <option>3-4</option>
                  <option>4-5</option>
                  <option>5-6</option>
                  <option>6-7</option>
                  <option>7-8</option>
                  <option>8-9</option>
                  <option>10+</option>
                </select>
                <div className="h-3">
                  {errors.experience && (
                    <span className="text-xs text-red ">
                      {errors.experience}
                    </span>
                  )}
                </div>
              </div>

              {/* Current CTC */}
                <div className="w-full mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Current CTC</label>
                <input
                  type="number"
                  name="previoussalary"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Current CTC"
                  value={careerApply.previoussalary}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.previoussalary && (
                    <span className="text-xs text-red ">
                      {errors.previoussalary}
                    </span>
                  )}
                </div>
              </div>

              {/* Expected CTC */}
                <div className="w-full mb-4">
                   <label className="block text-sm font-medium text-gray-700 mb-1">Expected CTC</label>
                <input
                  type="number"
                  name="exceptedsalary"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Expected CTC"
                  value={careerApply.exceptedsalary}
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.exceptedsalary && (
                    <span className="text-xs text-red ">
                      {errors.exceptedsalary}
                    </span>
                  )}
                </div>
              </div>

              {/* Notice Period */}
                <div className="w-full mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Notice</label>
                <select
                  name="noticeperiod"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.noticeperiod}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Notice Period
                  </option>
                  <option>Immediate Joiner</option>
                  <option>15 days</option>
                  <option>30 days</option>
                  <option>60 days</option>
                </select>

                <div className="h-3">
                  {errors.noticeperiod && (
                    <span className="text-xs text-red ">
                      {errors.noticeperiod}
                    </span>
                  )}
                </div>
              </div>

              {/* Location */}
                <div className="w-full mb-4">
                  
                   <label className="block text-sm font-medium text-gray-700 mb-1">Location</label>
                <input
                  type="text"
                  name="location"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Location"
                  value={careerApply.location}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.location && (
                    <span className="text-xs text-red ">{errors.location}</span>
                  )}
                </div>
              </div>

              {/* Willing to Relocate */}
                <div className="w-full mb-4">
                   
                   <label className="block text-sm font-medium text-gray-700 mb-1"> Relocate</label>
                <select
                  name="willingToRelocate"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.willingToRelocate}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Willing to relocate?
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </select>

                <div className="h-3">
                  {errors.willingToRelocate && (
                    <span className="text-xs text-red ">
                      {errors.willingToRelocate}
                    </span>
                  )}
                </div>
              </div>

              {/* <label className="text-base mb-1">
                Referred by internal employee?
              </label>

              <div>
                <div className="w-full  flex">
                  <label>
                    <input
                      type="radio"
                      name="referredBy"
                      value="yes"
                      checked={careerApply.referredBy === "yes"}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label className="ml-4">
                    <input
                      type="radio"
                      name="referredBy"
                      value="no"
                      checked={careerApply.referredBy === "no"}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
                <div className="h-3 mb-4">
                  {errors.referredBy && (
                    <span className="text-xs text-red ">
                      {errors.referredBy}
                    </span>
                  )}
                </div>
              </div> */}

              {/* Resume Upload */}
             
                <div className="w-full mb-4">
                   <label className="text-base"> Resume</label>
              
                  <input
                    type="file"
                    name="resume"
                    accept=".pdf"
                    ref={fileInputRef} 
                    onChange={handleFileChange}
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                  />
                    <span className="text-xs text-gray  ">Only PDF files are accepted for upload</span>
                <div className="h-3 ">
                  {errors.resume && (
                    <span className="text-xs text-red ">{errors.resume}</span>
                  )}
                </div>
              </div>

              </div>

              <div className="flex items-center justify-end space-x-4">
              
                <button
                  type="submit"
                  className="px-6 py-2 bg-blue-600 text-white bg-black rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Submit Application
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Benefits Section */}
        {/* <div className="mt-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Why Join Us?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-sm text-center">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Target className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Career Growth</h3>
              <p className="text-gray-600">Opportunities for continuous learning and career advancement</p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm text-center">
              <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Users className="w-6 h-6 text-green-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Great Culture</h3>
              <p className="text-gray-600">Collaborative and inclusive work environment</p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <DollarSign className="w-6 h-6 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Competitive Benefits</h3>
              <p className="text-gray-600">Comprehensive compensation and benefits package</p>
            </div>
          </div>
        </div> */}
        
      </div>
    </div>
  );
};

export default Description;

