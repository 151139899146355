import React, { useState } from 'react';
import { MessageCircle, X } from 'lucide-react';
import Whasappicon from '../../../../Assets/InforPage/whatsappIcon.png'
const WhatsAppButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  
  const whatsappNumber = '9154673864';
  const defaultMessage = "Hello! I need a Demo on Infor Services.";
  
  const handleWhatsAppClick = () => {
    const encodedMessage = encodeURIComponent(message || defaultMessage);
    
    // Check if device is mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    // Use web.whatsapp.com for desktop and wa.me for mobile
    const whatsappUrl = isMobile
      ? `https://wa.me/${whatsappNumber}?text=${encodedMessage}`
      : `https://web.whatsapp.com/send/?phone=${whatsappNumber}&text=${encodedMessage}`;
      
    // Open in new tab
    window.open(whatsappUrl, '_blank');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleWhatsAppClick();
    setIsOpen(false);
    setMessage('');
  };

  return (
    <div className="fixed left-6 bottom-5 z-50">
      {/* Main Button */}
      <button
        style={{background:"#228B22"}}
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-green-500 hover:bg-green-600  text-white p-4 rounded-full shadow-lg transition-all duration-300 hover:scale-110 ${isOpen ? 'rotate-180' : ''}`}
        aria-label={isOpen ? 'Close WhatsApp form' : 'Open WhatsApp form'}
      >
        {isOpen ? (
          <X className="w-8 h-8" />
        ) : (
            // <MessageCircle className="w-8 h-8" />
            <img src={Whasappicon} alt="whatsapp"  className="w-8 h-8"/>
        )}
      </button>

      {/* Tooltip */}
      <div className={`absolute left-20 w-28 bottom-2 bg-white text-sm text-gray-600 py-1 px-3 rounded-lg shadow-md transition-opacity duration-300 ${isOpen ? 'opacity-0' : 'opacity-100'}`}>
        Chat with us on WhatsApp
      </div>

      {/* Popup Form */}
      <div className={`absolute bottom-20 left-0 transition-all duration-300 ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`}>
        <div className="bg-white rounded-lg shadow-xl p-6 w-72">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Send us a message
          </h3>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={defaultMessage}
                className="w-full h-24 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-green-500 resize-none"
              />
            </div>
            
            <div className="text-sm text-gray-500 mb-4">
              {navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)
                ? "Opens in WhatsApp app"
                : "Opens in WhatsApp Web"}
            </div>
            
            <button
              style={{color:"white",background:"#008000"}}
              type="submit"
              className="w-full border border-green bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center space-x-2"
            >
              <MessageCircle className="w-5 h-5" />
               {/* <img src={Whasappicon} alt="whatsapp"  className="w-8 h-8"/> */}
              <span>Start Chat</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppButton;


