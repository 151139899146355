// import React, { useRef, useEffect } from 'react';

// const InforClient = ({ logos = [] }) => {
//   const scrollRef = useRef(null);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const scrollContainer = scrollRef.current;
//     const container = containerRef.current;
//     let animationFrameId;
//     let scrollPosition = 0;

//     const animate = () => {
//       if (scrollContainer) {
//         // Move left by 1 pixel each frame
//         scrollPosition -= 1;
        
//         // Get the width of one set of logos
//         const singleSetWidth = scrollContainer.scrollWidth / 2;
        
//         // Reset position when we've scrolled one full set
//         if (Math.abs(scrollPosition) >= singleSetWidth) {
//           scrollPosition = 0;
//         }
        
//         scrollContainer.style.transform = `translateX(${scrollPosition}px)`;
//       }
//       animationFrameId = requestAnimationFrame(animate);
//     };

//     animate();

//     return () => {
//       cancelAnimationFrame(animationFrameId);
//     };
//   }, []);

//   if (!logos.length) {
//     return null;
//   }

//   return (
//     <div className="relative w-full overflow-hidden bg-gray-100">
//       <div className="container mx-auto px-4 py-8 md:py-16">
//         <div className="text-center mb-8 md:mb-12">
//           <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4" style={{ color: "#003366" }}>
//         Where Creativity Meets Recognition
//           </h2>
//           <p className=" md:text-xl max-w-2xl mx-auto text-center" style={{ color: "#4A4A4A" }}>
//             Transforming innovative visions into recognized business success through expert consulting solutions.
//           </p>
//         </div>

//         <div className="relative w-full max-w-6xl mx-auto">
//           {/* Gradient overlays */}
//           <div className="absolute inset-y-0 left-0 w-16 md:w-24 z-10 bg-gradient-to-r from-white to-transparent"></div>
//           <div className="absolute inset-y-0 right-0 w-16 md:w-24 z-10 bg-gradient-to-l from-white to-transparent"></div>

//           {/* Logo container */}
//           <div
//             ref={containerRef}
//             className="overflow-hidden mx-auto"
//             style={{ width: 'calc(100% - 8rem)' }} // Account for gradient overlays
//           >
//             <div
//               ref={scrollRef}
//               className="flex gap-6 md:gap-12 items-center"
//               style={{
//                 willChange: 'transform',
//                 width: 'fit-content' // Allow container to be as wide as needed
//               }}
//             >
//               {/* First set of logos */}
//               {logos.map((logo) => (
//                 <div
//                   key={`first-${logo.id}`}
//                   className="flex-shrink-0 h-12 w-24 md:h-16 md:w-32 lg:h-20 lg:w-40 flex items-center justify-center"
//                 >
//                   <img
//                     src={logo.src}
//                     alt={logo.alt}
//                     className="h-full w-auto object-contain transition-transform duration-300 hover:scale-125"
//                   />
//                 </div>
//               ))}
//               {/* Duplicate set of logos for seamless loop */}
//               {logos.map((logo) => (
//                 <div
//                   key={`second-${logo.id}`}
//                   className="flex-shrink-0 h-12 w-24 md:h-16 md:w-32 lg:h-20 lg:w-40 flex items-center justify-center"
//                 >
//                   <img
//                     src={logo.src}
//                     alt={logo.alt}
//                     className="h-full w-auto object-contain transition-transform duration-300 hover:scale-125"
//                   />
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InforClient;



import React, { useRef, useEffect } from 'react';

const InforClient = ({ logos = [] }) => {
  const scrollRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let animationFrameId;
    let scrollPosition = 0;

    const animate = () => {
      if (scrollContainer) {
        // Slower scroll speed for better visibility
        scrollPosition -= 0.5;
        
        // Get the width of one set of logos
        const singleSetWidth = scrollContainer.scrollWidth / 4;
        
        // Reset position when we've scrolled one full set
        if (Math.abs(scrollPosition) >= singleSetWidth) {
          scrollPosition = 0;
        }
        
        scrollContainer.style.transform = `translateX(${scrollPosition}px)`;
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  if (!logos.length) {
    return null;
  }

  // Create four sets of logos for smoother transition
  const duplicatedLogos = [...logos, ...logos, ...logos, ...logos];

  return (
    <div className="w-full max-w-5xl m-auto overflow-hidden bg-gradient-to-r from-blue-50 to-indigo-50">
      {/* Header Section */}
      <div className="text-center py-12 px-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
         Kapil Tech Infor Consulting Services
        </h1>
        <h2 className="text-lg text-gray-600 max-w-2xl mx-auto">
           Enterprise Automation with Infor ERP Implementation, Managed Services and CloudSuite Solutions
        </h2>
       
      </div>

      {/* Logo Scroll Section */}
      <div 
        ref={containerRef}
        className="relative w-full py-12"
      >
        {/* Gradient overlays for smooth fade effect */}
        <div className="absolute left-0 top-0 h-full w-40 bg-gradient-to-r from-blue-50 to-transparent z-10" />
        <div className="absolute right-0 top-0 h-full w-40 bg-gradient-to-l from-indigo-50 to-transparent z-10" />

        {/* Logo container */}
        <div 
          ref={scrollRef}
          className="flex items-center whitespace-nowrap"
        >
          {/* Four sets of logos for smoother infinite scroll */}
          {duplicatedLogos.map((logo, index) => (
            <div
              key={`logo-${index}`}
              className="mx-12 flex items-center justify-center"
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className="h-24 w-auto object-contain filter hover:brightness-110 transition-all duration-300 ease-in-out"
                style={{
                  minWidth: '200px',
                  maxWidth: '300px'
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InforClient;