import React, { useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import FinstaNav from '../FinstaNav';
const IntroCoreBanking = () => {
  const slides = [
    {
      title: "Innovative Solutions",
      description: "Transforming challenges into opportunities with cutting-edge technology.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/14.png?updatedAt=1734519963990"
    },
    {
      title: "Expert Consulting",
      description: "Tailored strategies to elevate your business to new heights.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/CoreBanking/2.png?updatedAt=1734519963386"
    },
    {
      title: "Seamless Integration",
      description: "Connecting your digital ecosystem with precision and efficiency.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/Intro/14%20-%20Copy.png?updatedAt=1735192049984"
    },
    {
      title: "Streamlined Financial Management",
      description: "optimizing your NBFC operations with cutting-edge software solutions.",
      imageSrc: "https://ik.imagekit.io/7wqj9br0b/Intro/15%20-%20Copy.png?updatedAt=1735192049830"
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  useEffect(() => {
    let intervalId;
    if (isAutoPlaying) {
      intervalId = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [isAutoPlaying, slides.length]);

  const goToSlide = useCallback((index) => {
    setCurrentSlide(index);
    setIsAutoPlaying(false);
    
    const timeoutId = setTimeout(() => {
      setIsAutoPlaying(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <div 
        className="relative min-h-screen text-white overflow-hidden"
        style={{
          background: '#E0F7FA',
        }}
      >
        {/* Navigation Bar */}
        {/* <nav className="flex flex-col sm:flex-row items-center justify-between py-3 sm:py-5 px-4 sm:px-10 bg-white bg-opacity-80 z-50">
        

          <div className="flex items-center space-x-4 sm:space-x-6 md:space-x-8">
            <Link
              to="/services/Finsta/Corebanking"
                 className='border-2 border-[#E6E6FA] px-2 py-3 flex justify-center items-center rounded-full space-x-2 transition duration-300 transform hover:scale-110 hover:bg-[#008080]  shadow-lg text-center m-auto w-full max-w-xs h-12 bg-black text-white tracking-wide sm:w-24 md:w-36 lg:w-36 xl:w-36'
                style={{ transition: 'color 0.5s ease' }}
              onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'white'}
            >
              Core Banking
            </Link>
            <Link
              to="/services/Finsta/Lending"
               className='border-2 border-[#E6E6FA] px-2 py-3 flex justify-center items-center rounded-full space-x-2 transition duration-300 transform hover:scale-110 hover:bg-[#008080]  shadow-lg text-center m-auto w-full max-w-xs h-12 bg-black text-white tracking-wide sm:w-24 md:w-36 lg:w-36 xl:w-36'
                style={{ transition: 'color 0.5s ease' }}
              onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'white'}

            >
              Lending
            </Link>
          </div>

        
          <div className="flex items-center mb-3 sm:mb-0">
            <Link  to="/services/Finsta" ><img src={FinstaLogo} alt="Finsta Logo" className="h-6 sm:h-8" /></Link>
          </div>
        </nav> */}
         <FinstaNav />
        {/* Background Decorative Gradient */}
        <div
          className="absolute inset-0 z-10 pointer-events-none"
          style={{
            background: 'radial-gradient(circle at 75% 25%, rgba(255,255,255,0.1) 0%, transparent 50%)',
            opacity: 0.5
          }}
        />

        {/* Carousel */}
        <div className="relative w-full max-w-7xl mx-auto overflow-hidden px-4 sm:px-6">
          {/* Slide Container */}
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{ 
              transform: `translateX(-${currentSlide * 100}%)`,
              height: 'calc(100vh - 80px)',
              maxHeight: '800px'
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="flex-none w-full flex flex-col md:flex-row items-center justify-center p-4 sm:p-8 gap-6 md:gap-0"
                style={{ minWidth: '100%' }}
              >
                {/* Left Content: Text */}
                <div className="w-full md:w-3/5 space-y-4 sm:space-y-6 text-center md:text-left">
                  <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight text-white drop-shadow-lg" 
                       style={{color:"#001F3F"}}>
                    {slide.title}
                  </h2>
                  <p className="text-base sm:text-lg md:text-xl text-white/90 max-w-xl mx-auto md:mx-0" 
                     style={{color:"#00796B"}}>
                    {slide.description}
                  </p>
                  <div className="flex items-center justify-center md:justify-start space-x-4">
                    <Link 
                      to="/contact"
                      style={{background:"#008080"}}
                      className=" text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold flex items-center space-x-2 hover:bg-cyan-100 transition duration-300 transform hover:scale-105 shadow-lg text-sm sm:text-base"
                    >
                      <span>Talk to our team</span>
                    </Link>
                  </div>
                </div>

                {/* Right Content: Image */}
                <div className="w-full md:w-1/2 flex justify-center">
                  <img
                    src={slide.imageSrc}
                    alt="Slide Illustration"
                    className="w-64 sm:w-80 md:w-96 object-contain"
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Navigation Dots */}
          <div className="absolute bottom-4 sm:bottom-8 left-0 right-0 flex justify-center space-x-2 sm:space-x-3">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`
                  w-2 h-2 rounded-full transition-all duration-300 ease-in-out
                  ${index === currentSlide 
                    ? 'bg-white scale-125 shadow-lg' 
                    : 'bg-black hover:bg-gray-300'}
                `}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroCoreBanking;