
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import LifeSciences from '../../../Assets/LifeScience.png';
import ClinicalTrails from '../../../Assets/Clinical Trials.png';
import lifesciencemethod from '../../../Assets/Life Sciences Method.png';
import digital_health from '../../../Assets/Digital Health Solutions.png'

const LifeScience = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Innovative Solutions for Life Sciences"
        paragragh={
          <>
            Our cutting-edge technologies and tailored solutions empower life
            sciences organizations to accelerate research, improve patient
            outcomes, and drive innovation in healthcare. From pharmaceuticals
            to biotechnology, we help you transform ideas into reality.
          </>
        }
        imgScr={LifeSciences}
      
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Clinical Trials and Research Optimization"
        paragraph={
          <>
            Streamline your clinical trial processes with our comprehensive
            digital solutions. From patient recruitment to data management, we
            offer tools that enhance efficiency, reduce time to market, and
            improve the accuracy of trial results.
          </>
        }
        imgsrc={ClinicalTrails}
      />
      <HeadingParagraphSection
        heading="Data-Driven Decision Making in Healthcare"
        paragraph={
          <>
            Leverage advanced analytics and AI to make informed decisions across
            research, development, and patient care. Our data-driven solutions
            provide real-time insights, optimize processes, and improve outcomes
            in life sciences and healthcare sectors.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Supply Chain and Logistics in Life Sciences"
        paragraph={
          <>
            Ensure the efficient, secure, and timely delivery of life-saving
            products. Our supply chain management services help optimize cold
            chain logistics, enhance inventory management, and ensure
            traceability across the global healthcare ecosystem.
          </>
        }
        imgsrc={lifesciencemethod}
      />
      <Image_ContentReusableComponent
        heading="Patient-Centric Digital Health Solutions"
        paragraph={
          <>
           <p> We provide digital solutions that improve patient engagement and
            care delivery. From telemedicine platforms to wearable health
            technology, our innovations empower patients and healthcare
            providers alike for better health outcomes.
            </p>
            Each section can be expanded based on your company's services,
            highlighting strengths in research, development, compliance, and
            manufacturing.
          </>
        }
        imgsrc={digital_health}
      />
    </div>
  );
};

export default LifeScience;
