import CaseStudies from "../../componentLayer/pages/CaseStudies";
import Home from "../../componentLayer/pages/home/Home";
import HomePage from "../../componentLayer/pages/home/HomePage";

export const homeRouter = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "casestudies",
    element: <CaseStudies />,
  },

  // {
  //   path:"contact",
  //   index: true,
  //   element: <Contact />,
  // },
];
