// import React, { useState } from 'react';

// const ServiceCards = () => {
//   const [hoveredIndex, setHoveredIndex] = useState(null);

//   const services = [
//     {
//       title: "Consultation & Assessment",
//       description: "Our journey begins with an in-depth consultation to understand your business processes, challenges, and goals. We perform a comprehensive assessment to identify gaps in your current systems and evaluate how Infor LN can address them.",
//       image: "https://img.freepik.com/free-photo/business-concept-with-team-close-up_23-2149151159.jpg?uid=R117373197&ga=GA1.1.1762279399.1733727878&semt=ais_hybrid",
//     },
//     {
//       title: "Strategic Planning",
//       description: "Develop comprehensive strategic roadmaps for implementation success.",
//       image: "https://img.freepik.com/free-photo/business-people-shaking-hands-together_53876-13391.jpg?uid=R117373197&ga=GA1.1.1762279399.1733727878&semt=ais_hybrid",
//     },
//     {
//       title: "Implementation & Customization",
//       description: "Expert implementation services with customized solutions.",
//       image: "https://img.freepik.com/free-photo/close-up-businessman-with-digital-tablet_1098-549.jpg?uid=R117373197&ga=GA1.1.1762279399.1733727878&semt=ais_hybrid",
//     },
//     {
//       title: "Data Migration & Integration",
//       description: "Seamless data migration and system integration services.",
//       image: "https://img.freepik.com/free-photo/business-people-shaking-hands-greeting_53876-96074.jpg?uid=R117373197&ga=GA1.1.1762279399.1733727878&semt=ais_hybrid",
//     },
//     {
//       title: "Change Management",
//       description: "Comprehensive change management and adoption strategies.",
//       image: "https://img.freepik.com/free-photo/business-success-report-graph-concept_53876-124755.jpg?uid=R117373197&ga=GA1.1.1762279399.1733727878&semt=ais_hybrid",
//     },
//     {
//       title: "Monitoring & Optimization",
//       description: "Continuous monitoring and performance optimization.",
//       image: "https://img.freepik.com/free-photo/businessman-with-tablet-after-closing-deal_1098-3372.jpg?uid=R117373197&ga=GA1.1.1762279399.1733727878&semt=ais_hybrid",
//     }
//   ];

//   return (
//     <>
      
//       <div>
//         <h2 className='text-center font-bold text-black '>Empower Your Business with  Infor Services</h2>
//         <p className='text-center text-gray-200 '>Transforming Challenges into Opportunities through Expert Consulting, Seamless Implementation, and Strategic Cloud Migration.</p>
//         </div>
//      <div className="flex flex-col lg:flex-row gap-3 p-4 w-full max-w-5xl mx-auto">
//       {services.map((service, index) => (
//         <div
//           key={index}
//           className={`relative overflow-hidden transition-all duration-500 ease-in-out cursor-pointer
//             ${hoveredIndex === null
//               ? (index === 0 ? 'lg:w-[35%]' : 'lg:w-[13%]')
//               : hoveredIndex === index
//                 ? 'lg:w-[35%]'
//                 : 'lg:w-[8%]'
//             }
//             w-full min-h-[500px] rounded-xl shadow-lg`}
//           onMouseEnter={() => setHoveredIndex(index)}
//           onMouseLeave={() => setHoveredIndex(null)}
//         >
//           <img
//             src={service.image}
//             alt={service.title}
//             className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 hover:scale-105"
//           />
//           <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/70">
//             {/* Default state content */}
//             <div className={`h-full w-full flex items-center justify-center transition-all duration-500
//               ${hoveredIndex === index ? 'opacity-0' : 'opacity-100'}`}>
//               <h3 className="text-white font-bold text-lg transform -rotate-90 whitespace-nowrap tracking-wider">
//                 {service.title}
//               </h3>
//             </div>
            
//             {/* Hover state content */}
//             <div className={`absolute inset-0 p-8 text-black transition-all duration-500
//               ${hoveredIndex === index ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-4'}`}>
//               <div className="h-full flex flex-col justify-end">
//                 <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
//                 <p className="text-base leading-relaxed">{service.description}</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//       </div>
      
      
//     </>
//   );
// };

// export default ServiceCards;


import React, { useState } from 'react';

const ServiceCards = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const services = [
    {
      title: "Root Cause Identification",
      description: "You're seeing symptoms of a larger issue, but unsure of the origin? We specialize in Root Cause Analysis (RCA) to uncover the heart of the problem, whether it stems from ERP implementation challenges, inefficient internal processes, or other underlying factors. By identifying and addressing the root causes, we provide sustainable solutions that prevent recurring issues and optimize your operations",
      image: "https://ik.imagekit.io/kqumni8l2/INFOR_SERVICES_IMAGES/52%201.png?updatedAt=1737450587042"
    },
    {
      title: "Strategic Infor Implementation",
      description: "We begin by thoroughly assessing your current business processes and identifying areas where Infor ERP can deliver the most value. Our consultants then work closely with your team to design and implement a tailored Infor solution — whether it's a cloud deployment or on-premise — that aligns with your specific needs, improves efficiency, reduces costs, and supports your long-term goals. This includes core team training in Infor Campus to ensure a baseline functional understanding of the ERP and its capabilities",
      image: "https://ik.imagekit.io/kqumni8l2/INFOR_SERVICES_IMAGES/53%201.png?updatedAt=1737450587154"
    },
    {
      title: "Comprehensive System Integration",
      description: "Our team ensures that your Infor ERP system integrates seamlessly with your existing technology stack, leveraging Infor's Intelligent Open Network (ION) or DCKAP Integrator API for streamlined data synchronization. We handle all aspects of system customization, data migration, and API integration, ensuring that your operations remain uninterrupted and that the new system aligns perfectly with your business processes.",
      image: "https://ik.imagekit.io/kqumni8l2/INFOR_SERVICES_IMAGES/1.jpg?updatedAt=1737449856837"
    },
    {
      title: "Full Service Experience",
      description: "Building on years of experience and hundreds of successful client engagements, Kapil Tech is your trusted partner for ongoing Infor ERP support and innovation. Whether you need expert consulting, custom application development, process integration, or cloud solutions, our CMMI Level 3 certified team is well-equipped to help you optimize your Infor environment and achieve your business goals.",
      image: "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
    },
    {
      title: "Continuous Improvement and Support",
      description: "Kapil Tech remains your trusted partner long after the initial Infor ERP implementation, providing comprehensive ongoing support tailored to your specific needs, including access to Infor LX experts, custom report writing, and assistance with system conversions and imports. We offer continuous system optimization, database administration, and targeted training workshops to help your team fully leverage the capabilities of Infor ERP, ensuring that your system continues to deliver value as your business grows and evolves.",
      image: "https://ik.imagekit.io/kqumni8l2/INFOR_SERVICES_IMAGES/54%201.png?updatedAt=1737450589564"
    },
    {
      title: "Procedure Development",
      description: "Software supports your processes, not dictate them. After thoroughly understanding the issue you're facing, we collaborate with you to develop customized procedures that strategically leverage the powerful features of your Infor ERP software, ensuring it aligns seamlessly with your unique business needs and optimizes your operational efficiency.",
      image: "https://ik.imagekit.io/kqumni8l2/INFOR_SERVICES_IMAGES/2.jpg?updatedAt=1737449874664"
    }
  ];

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-12">
      <div className="mb-12 text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          Our Implementation Approach
        </h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          A proven methodology ensuring successful implementation and adoption of your business solutions
        </p>
      </div>

      <div className="flex flex-col lg:flex-row gap-3 w-full justify-center">
        {services.map((service, index) => (
          <div
            key={index}
            className={`relative overflow-hidden transition-all duration-500 ease-in-out cursor-pointer
              ${hoveredIndex === null
                ? 'lg:w-[16.66%]' 
                : hoveredIndex === index
                  ? 'lg:w-[40%]' 
                  : 'lg:w-[12%]'} // Narrower for others when one is hovered
              w-full h-[500px] rounded-2xl shadow-lg`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            {/* Background Image */}
            <div 
              className="absolute inset-0 brightness-75  bg-cover bg-center  transition-transform duration-500 hover:scale-110"
              style={{ backgroundImage: `url(${service.image})` }}
            />
            
            {/* Overlay */}
            <div className="absolute   inset-0 bg-gradient-to-black from-black/40 to-black/90" >
              {/* Vertical title for non-hovered state */}
              <div className={`h-full w-full flex items-center justify-center transition-all duration-500
                ${hoveredIndex === index ? 'opacity-0 ' : 'opacity-100'} ` } >
                <h3 className="text-white  font-bold text-lg transform -rotate-90 whitespace-nowrap tracking-wider" >
                  {service.title}
                </h3>
              </div>
              
              {/* Content for hovered state */}
              <div style={{color:"white"}} className={`absolute inset-0 p-8 text-white transition-all duration-500
                ${hoveredIndex === index ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform translate-y-4'}`}>
                <div className="h-full flex flex-col justify-end">
                  <h3 className="text-2xl font-bold mb-4 text-white ">{service.title}</h3>
                  <p className="text-base text-sm leading-relaxed opacity-90 text-white ">{service.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCards;