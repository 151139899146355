
import { Link, useOutletContext } from "react-router-dom";
import It_services from "../../../Assets/IT_Servicesss.png";
import Data_migration from "../../../Assets/Data_migration.png";
import WareHouse from "../../../Assets/WareHouse (2).png";
import Incident_mangemenet from "../../../Assets/Incident_mangement.png";
import ERP_tool from "../../../Assets/ERP_tool.png";
import groupteam from "../../../Assets/blogimg3.png";

import { useScrollAnimation } from "../../../utils/Utils";

import About from "../../../Assets/AboutUspng.png"
const AboutUs = () => {
  const { linkClicked } = useOutletContext();
  useScrollAnimation();
  return (
    <div>
       <div className="w-full max-w-full h-auto m-auto ">
      <video 
        src="https://ik.imagekit.io/hjiuirrf7/KapilLogoRevealBanner.mp4?tr=orig&updatedAt=1732865386212" 
        className="w-full h-auto " 
        preload="metadata" 
        autoPlay 
        muted 
        playsInline
      >
        Your browser does not support the video tag.
      </video>
    </div>
      <section className="bg-[#efefef]">
      
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 xl:mx-40 mx-3 md:mx-6">
         
    {/* Text Content */}
    <div className="col-span-1 xl:my-auto my-5 mx-3 lg:mx-9 order-2 lg:order-1 flex flex-col justify-center">
      <h1 className="text-2xl font-bold mb-2">Company Overview</h1>
      <h2 className="text-xl font-semibold mb-4">Insights You Can Act On</h2>
      <p className="text-gray-700">
        We are committed to making our story about you and your successful journey, serving as an insightful and resourceful partner.
      </p>
    </div>

    {/* Video Logo Reveal */}
    <div className="col-span-1 flex items-center justify-center order-1 lg:order-2">
      <div className="flex justify-center py-10 w-full h-full max-w-lg">
       <img src={About} alt="logo" />
      </div>
    </div>
  </div>
</section>
      <section className=" text-center  border border-y-[#9ca3af] border-x-0">
        <div className=" my-9 flex  items-center justify-center">
          <ul className="inline">
            {["Manufacturing", "Corporate Governance", "ESG"].map(
              (text, index) => (
                <li
                  key={index}
                  className="inline-block mx-3 w-auto text-center mb-3"
                >
                  <span className="text-bluecolor border-[#4e8ecb] bg-transparent font-normal inline-block w-auto h-auto py-[3.5px] px-5 align-middle leading-[30px] rounded-full border transition-all duration-200 ease-linear">
                    {text}
                  </span>
                </li>
              )
            )}
          </ul>
        </div>
      </section>
      <section className="py-10 bg-[#efefef]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1   order-2 lg:order-1 my-auto animate-up">
            <h2>About us</h2>
            <p className="text-paracolor whitespace-normal " style={{ overflowWrap: 'normal', whiteSpace: 'normal', wordBreak: 'break-word' }}>
            Kapil Technologies (KCS) is a leading global IT solutions provider with a strong presence in India, the USA, Indonesia, Malaysia, and Singapore. We specialize in end-to-end services, including comprehensive ERP implementations, advanced web and mobile application development, and innovative marketing solutions.
 
Our team of experts is committed to delivering tailored solutions that drive digital transformation, leveraging the latest technologies to help businesses optimize their operations and maintain a competitive edge. From concept to deployment, we ensure seamless delivery of scalable, future-ready solutions.
            
            </p>
          </div>
          <div class="w-[]">
            <img src="https://ik.imagekit.io/kqumni8l2/PhotoCollab/PhotoCollab_4.jfif?updatedAt=1737458782705" alt=""  className="object-contain" />
          </div>
        </div>
      </section>
     
     <section className="py-16 bg-gray-900">
  <h1 className="text-3xl font-semibold text-black ps-10 xl:ps-24 mb-10">
    Case Studies
  </h1>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 xl:mx-10 mx-3 md:mx-10">
    {/* Card 1 */}
    <div className="bg-white  rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={Incident_mangemenet}
        alt="Incident Management"
        className="w-full h-48 object-contain"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Managed services in Infor enterprise solutions
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          An industrial equipment manufacturer in GCC region has put full trust
          on Kapil Tech for Managed services in Infor enterprise solutions
          consecutive third year. Even complex Incidents are resolved in record
          2 hours timeline.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>

    {/* Card 2 */}
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={ERP_tool}
        alt="ERP Tool"
        className="w-full h-48 object-cover"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Process integration with Infor and third-party solutions
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          Extensions are built in the standard ERP feature pack for process
          integration with both Infor and third-party solutions. Same
          extensions and integration components worked even after solution
          upgrades.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>

    {/* Card 3 */}
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={Data_migration}
        alt="Data Migration"
        className="w-full h-48 object-cover"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Migration and retrofitting around 10 extended functionalities
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          Successful migration and retrofitting around 10 extended
          functionalities from older version to a later version of Infor ERP
          for an Indian enterprise of exclusive business scenarios.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>

    {/* Card 4 */}
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={WareHouse}
        alt="Warehouse Management"
        className="w-full h-48 object-cover"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Warehouse management and Supplier collaboration
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          Edge solutions for Warehouse management and Supplier collaboration
          are deployed with custom BOD integrations. Fulfilling Customer
          objective did lead to longer association for support services.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>
  </div>
</section>
   
      <section className="py-10 bg-[#fff]">
        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-9 xl:mx-40 mx-3 md:mx-10 ">
          <div className="col-span-2 animate-up">
            <div className="  rounded overflow-hidden shadow-lg bg-[#fff] card-container">
              <div className="imagehover relative">
                <img src={groupteam} alt="WareHouse" className="w-full" />
                <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
              </div>
              <div className="px-6 py-4">
                <h3 className="pb-[12px] font-bold">
                  Warehouse management and Supplier collaboration
                </h3>
                <Link
                  className="hover-link transition-colors duration-300 flex-grow"
                  to="casestudies"
                  onClick={() => linkClicked()}
                >
                  <p>
                    Edge solutions for Warehouse management and Supplier
                    collaboration are deployed with custom BOD integrations.
                  </p>
                </Link>

                <p className="my-10  flex items-center gap-1 group hover:text-darkblue">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-3 animate-up">
            <div className=" flex lg:gap-5 ">
              <div className=" max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container">
                <div className="imagehover relative">
                  <img src={WareHouse} alt="WareHouse" className="w-full" />
                  <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
                </div>
                <div className="px-6 py-4">
                  <h3 className="pb-[12px] font-bold">
                    Warehouse management and Supplier collaboration
                  </h3>
                  <Link
                    className="hover-link transition-colors duration-300 flex-grow"
                    to="casestudies"
                    onClick={() => linkClicked()}
                  >
                    <p>
                      Edge solutions for Warehouse management and Supplier
                      collaboration are deployed with custom BOD integrations.
                    </p>
                  </Link>

                  <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className=" max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container">
                <div className="imagehover relative">
                  <img src={It_services} alt="It_services" className="w-full" />
                  <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
                </div>
                <div className="px-6 py-4">
                  <h3 className="pb-[12px] font-bold">
                    Warehouse management and Supplier collaboration
                  </h3>
                  <Link
                    className="hover-link transition-colors duration-300 flex-grow"
                    to="casestudies"
                    onClick={() => linkClicked()}
                  >
                    <p>
                      Edge solutions for Warehouse management and Supplier
                      collaboration are deployed with custom BOD integrations.
                    </p>
                  </Link>

                  <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-6 ps-10  bg-gradient-custom hover:bg-gradient-custom-reverse">
              <Link
                to="casestudies"
                className="text-md text-[#fff] flex items-center "
                onClick={() => linkClicked()}
              >
                <p className="mt-3 mb-2 flex items-center gap-1 group hover:text-darkblue py-1">
                  <span className="mr-2 hover:underline">Learn More</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </p>

                {/* <span className="text-3xl">&#8594;</span> */}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
