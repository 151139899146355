
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import Food_beverange from '../../../Assets/Food_Beverange.png';
import Food_test from '../../../Assets/Food_test.png';
import Product_inovation from '../../../Assets/Product Innovation.png';
import Beverage_industries from '../../../Assets/Beverage Industry.png'
const FoodAndBeverage = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Innovative Solutions for the Food and 
Beverage Industry"
        paragragh={
          <>
            We provide cutting-edge technology and tailored solutions to address
            the unique challenges of the food and beverage sector. From product
            development to production optimization, we help businesses deliver
            safe, high-quality products to the market
          </>
        }
        imgScr={Food_beverange}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Ensuring Food Safety and Quality 
Compliance"
        paragraph={
          <>
            Stay ahead of regulatory requirements with our advanced quality
            control systems. We provide tools to help you maintain food safety
            standards, reduce contamination risks, and ensure compliance with
            global certifications like HACCP and ISO.
          </>
        }
        imgsrc={Food_test}
      />
      <HeadingParagraphSection
        heading="Sustainable Practices in Food Production"
        paragraph={
          <>
            We support sustainable practices in food production, reducing waste
            and promoting the use of renewable resources. Our green solutions
            help minimize environmental impact while maintaining high
            operational efficiency.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Enhancing Product Innovation and 
Development"
        paragraph={
          <>
            With consumer preferences constantly evolving, we offer tools and
            solutions that enable rapid product development. Whether it’s new
            flavors, healthier options, or innovative packaging, we help you
            stay ahead of the competition.
          </>
        }
        imgsrc={Product_inovation}
        
      />
      <Image_ContentReusableComponent
        heading="Customized Solutions for Beverage 
Industry
"
        paragraph={
          <>
           <p> We cater to the specific needs of beverage manufacturers, whether
            it’s soft drinks, alcoholic beverages, or bottled water. From
            ingredient sourcing to packaging and distribution, we streamline
            every aspect of the production process.</p>
      
            This structure balances the technical and business aspects while
            highlighting your company's expertise in the food and beverage
            sector.
          </>
        }
        imgsrc={Beverage_industries}
      />
    </div>
  );
};

export default FoodAndBeverage;
