

// import React, { useEffect, useRef, useState } from "react";
// import { Link, NavLink, useOutletContext } from "react-router-dom";
// import Kapil_Technologies_logo from "../../../Assets/Kapil Technlogies Logo.png";
// import Services from "./Services";
// import Industries from "./Industries";
// import Insights from "./Insights";
// import Partners_Allianzes from "./Partners_Allianzes";

// export const DropdownIcon = ({ isOpen }) =>


//   isOpen ? (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       fill="none"
//       viewBox="0 0 24 24"
//       strokeWidth="1.5"
//       stroke="currentColor"
//       className="w-4 h-4"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M4.5 15.75l7.5-7.5 7.5 7.5"
//       />
//     </svg>
//   ) : (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       fill="none"
//       viewBox="0 0 24 24"
//       strokeWidth="1.5"
//       stroke="currentColor"
//       className="w-4 h-4"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M19.5 8.25l-7.5 7.5-7.5-7.5"
//       />
//     </svg>
//   );
// const Navbar = ({ linkClicked }) => {
  

  

  


//   const MenuIcon = ({ open }) =>
//     open ? (
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth="1.5"
//         stroke="currentColor"
//         className="w-6 h-6"
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           d="M6 18L18 6M6 6l12 12"
//         />
//       </svg>
//     ) : (
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth="1.5"
//         stroke="currentColor"
//         className="w-6 h-6"
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           d="M10.5 6h9.75M10.5 6a1.5 1.5 0 10-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 10-3 0m3 0a1.5 1.5 0 10-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 10-3 0m3 0a1.5 1.5 0 10-3 0m-9.75 0h9.75"
//         />
//       </svg>
//     );

//   const servicesLinks = {
//     enterprise_technologies: {
//       name: "ENTERPRISE TECHNOLOGIES AND SERVICES",
//       links: {
//         subLink1: [
//           // {
//           //   name: "Business Counsulting",
//           //   link: "/services/business-consulting",
//           // },
          
//           { name: "Digital", link: "/services/digital" },
//           {
//             name: "Oracle",
//             link: "/services/oracle_and_id_edwards",
//           },
//           { name: "SAP", link: "/services/sap" },
//           { name: "Infor", link: "/services/infor" },
//           { name: " Microsoft", link: "/services/microsoft" },
//           { name: "Odoo", link: "/services/odoo" },
//           // { name: "PTC", link: "/services/ptc" },
//           { name: "AWS", link: "/services/aws" },
//           { name: "Google Cloud", link: "/services/googlecloud" },
         
//         ],
//         subLink2: [
//          {
//             name: "Customer Relationship Management",
//             link: "/services/customer_relationship_management",
//           },
//           {
//             name: " Manufacturing Execution Systems",
//             link: "/services/manufacturing_execution_systems",
//           },
//           {
//             name: "Product Lifecycle Management",
//             link: "/services/product_lifecycle_management",
//           },
//           {
//             name: "Supply Chain Management",
//             link: "/services/supply_chain_management",
//           },
//           {
//             name: "Environmental Social & Governance",
//             link: "/services/environmental_social_governance",
//           },
//           { name: "IT Transformation", link: "/services/it_transformation" },
//           {
//             name: "Applications Management",
//             link: "/services/applications_management",
//           },
          
//           { name: "Application Development", link: "/services/development" },
//           { name: "Testing", link: "/services/testing" },
//         ],
//       },
//     },
//     // Services: {
//     //   name: "SERVICES",
//     //   links: {
//     //     subLink1: [{ name: "Development", link: "/services/development" }],
//     //   },
//     // },
//     Products: {
//       name: "PRODUCTS",
//       links: {
//         subLink1: [
//           // { name: "Kapil Technologies 360 Degrees", link: "/services/Kapil Technologies360-degrees" },
//           { name: "Finsta", link: "/services/finsta" },
//           // { name: "EZChit", link: "/services/easychits" },
//           { name: "Hexabuild", link: "/services/hexabuilt" },

//           { name: "IFCA", link: "/services/ifca" },

//           {
//             name: "MES (Manufacturing Execution System)",
//             link: "/services/mes",
//           },
//         ],

//         subLink2: [
//           { name: "QRRA (PFA)", link: "/services/qrra" },

//           { name: "MPDV", link: "/services/mpdv" },
//           {
//             name: "SPS ( Smart Producions Solitions)",
//             link: "/services/spa",
//           },
//           { name: "Mobillor (PFA)", link: "/services/mobillor" },
//         ],
//       },
//     },
//   };
//   const industriesLinks = {
//     industries_1: {
//       links: {
//         subLink1: [
//           { name: "Automotive", link: "/industries/automotive" },
//           { name: "Construction", link: "/industries/construction" },
//           { name: "Energy", link: "/industries/energy" },

//           { name: "Equipment", link: "/industries/equipment" },
//           { name: "Food and Beverage", link: "/industries/food_and_beverage" },
//         ],
//       },
//     },
//     industries_2: {
//       links: {
//         subLink1: [
//           {
//             name: "Industrial Machinery and Equipment",
//             link: "/industries/industrial_macinery_and_equipment",
//           },
//           {
//             name: "Industrial Manufacturing",
//             link: "/industries/industrial_manufacturing",
//           },
//           {
//             name: "Life Sciences",
//             link: "/industries/life_sciences",
//           },
//           {
//             name: "High Tech and Electronics",
//             link: "/industries/high_tech_and_electrinics",
//           },
//         ],
//       },
//     },
//   };

//   const insightsLinks = {
//     insights_1: {
//       links: {
//         subLink1: [
//           { name: "About us", link: "/insights/aboutus" },
//           // { name: "Leadership", link: "/insights" },
//           // { name: "Customer Stories", link: "/insights/customerstories" },
//           { name: "Webinar", link: "/insights/webinar" },
//           // { name: " Blog", link: "/insights/blog" },
//         ],
//       },
//     },
//   };

//   const partners_allianzes_Links = {
//     partners_allianzes_1: {
//       links: {
//         subLink1: [
//           { name: "Partners", link: "/partner_allianzes/partner" },
//           { name: "Alliances", link: "/" },
//         ],
//       },
//     },
//   };
//   const [open, setOpen] = useState(false);
//   const [active, setActive] = useState("");
//   const [smallScreenActive, setSmallScreenActive] = useState("");

//   const [smallScreenSubactive, setSmallScreenSubActive] = useState("");

//   const servicesPopupRef = useRef(null);
//   const servicesPopupArrowRef = useRef(null);
//   const industriesPopupRef = useRef(null);
//   const industriesPopupArrowRef = useRef(null);
//   const insightsPopupRef = useRef(null);
//   const insightsPopupArrowRef = useRef(null);
//   const partners_allianzesPopupRef = useRef(null);
//   const partners_allianzesPopupArrowRef = useRef(null);

//   const ActiveRef = useRef(active);
//   useEffect(() => {
//     ActiveRef.current = active;
//   }, [active]);

//   const handleClickOutside = (event) => {
//     if (ActiveRef.current === "services") {
//       if (
//         servicesPopupRef.current &&
//         !servicesPopupRef.current.contains(event.target) &&
//         !servicesPopupArrowRef.current.contains(event.target)
//       ) {
//         setActive("");
//       }
//     } else if (ActiveRef.current === "industries") {
//       if (
//         industriesPopupRef.current &&
//         !industriesPopupRef.current.contains(event.target) &&
//         !industriesPopupArrowRef.current.contains(event.target)
//       ) {
//         setActive("");
//       }
//     } else if (ActiveRef.current === "insights") {
//       if (
//         insightsPopupRef.current &&
//         !insightsPopupRef.current.contains(event.target) &&
//         !insightsPopupArrowRef.current.contains(event.target)
//       ) {
//         setActive("");
//       }
//     } else if (ActiveRef.current === "partners_allianzes") {
//       if (
//         partners_allianzesPopupRef.current &&
//         !partners_allianzesPopupRef.current.contains(event.target) &&
//         !partners_allianzesPopupArrowRef.current.contains(event.target)
//       ) {
//         setActive("");
//       }
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);
 
//   return (
//     <nav className="bg-[#fff] shadow-lg xl:sticky xl:top-0  xl:z-50  border-t-red-500  border-r-red-500  border-red border-t-red-500 border-4 border-l-white border-r-red border-b-white ">

//          <div className="relative w-full h-1 bg-gradient-to-r from-red-500 to-red-600">
//         <div className="absolute right-0 w-6 h-8 bg-red-500 transform skew-x-12"></div>
//       </div>


//       <div className="flex items-center font-medium justify-between xl:h-16 lg:px-10">
//         <div className="z-50 p-5 xl:w-auto w-full flex justify-between ">
//           <Link to="/">
//             <img
//               src={Kapil_Technologies_logo}
//               alt="Kapil_Technologies_logo"
//               className="xl:cursor-pointer h-16 py-1"
//           onClick={()=>{linkClicked()}}
  
//             />
//           </Link>

//           <div className="xl:hidden" onClick={() => setOpen(!open)}>
//             <MenuIcon open={open} />
//           </div>
//         </div>
//         {/* ***********navlinks for large screen*********** */}

//         <ul className="xl:flex hidden   items-center gap-6  relative">
//           {/* <li className=""> */}
//           {/* <NavLink
//               to="/"
//               className="xl:py-1 py-3 px-3 inline-block nav_text hover:border-b-2 border-[#000]"
//               onClick={() => {
//                 if (active !== "home") {
//                   setActive("home");
//                 } else {
//                   setActive("");
//                 }
//               }}
//             >
//               Home
//             </NavLink>
//           </li> */}
//           <li className="">
//             <NavLink
       
//               to="/"
//               className="xl:py-1 py-3 px-3 inline-block nav_text hover:border-b-2 hover:text-[red]"
//               onClick={() => {
//                 if (active !== "home") {
//                   setActive("home");
//                 } else {
//                   setActive("");
//                 }
//                 linkClicked()
//               }}
              
               
//             >
//               Home
//             </NavLink>
//           </li>
//           <li className="" ref={servicesPopupArrowRef}>
//           <span
//               className={`xl:py-1 py-3 px-3  nav_text hover:text-red  ${
//                 active === "services" ? "border-b-2 hover:border-[red] " : ""
//               } xl:flex items-center gap-1 hidden cursor-pointer`}
//               onClick={() => {
//                 if (active !== "services") {
//                   setActive("services");
//                 } else {
//                   setActive("");
//                 }
//               }}
//             >
             
//               Services
//               <DropdownIcon  isOpen={active === "services"} />
//               </span>
           
//           </li>
//           <li className="" ref={industriesPopupArrowRef}>
//             <span
//               className={` xl:py-1 py-3 px-3  nav_text hover:text-red  ${
//                 active === "industries" ? "border-b-2 hover:border-[red] " : ""
//               }  xl:flex items-center gap-1 hidden cursor-pointer `}
//               onClick={() => {
//                 if (active !== "industries") {
//                   setActive("industries");
//                 } else {
//                   setActive("");
//                 }
//               }}
//             >
//               Industries
//               <DropdownIcon isOpen={active === "industries"} />
//             </span>
//           </li>
//           <li className="" ref={insightsPopupArrowRef}>
//             <span
//               className={`xl:py-1 py-3 px-3 nav_text hover:text-red  ${
//                 active === "insights" ? "border-b-2 border-[#000] hover:border-red " : ""
//               } xl:flex items-center gap-1 hidden cursor-pointer `}
//               onClick={() => setActive(active !== "insights" ? "insights" : "")}
//             >
//               Insights
//               <DropdownIcon isOpen={active === "insights"} />
//             </span>
//             {active === "insights" && (
//               <div ref={insightsPopupRef}>
//                 <Insights insightsLinks={insightsLinks} setActive={setActive} linkClicked={linkClicked}   />
//               </div>
//             )}
//           </li>
//           {/* <li className="" ref={partners_allianzesPopupArrowRef}>
//             <span
//               className={`
//                  xl:py-1 py-3 px-3  nav_text ${
//                    active === "partners_allianzes"
//                      ? "border-b-2 border-[#000]"
//                      : ""
//                  }  xl:flex items-center gap-1 hidden cursor-pointer `}
//               onClick={() => {
//                 if (active !== "partners_allianzes") {
//                   setActive("partners_allianzes");
//                 } else {
//                   setActive("");
//                 }
//               }}
//             >
//               Partners & Alliances
//               <DropdownIcon isOpen={active === "partners_allianzes"} />
//             </span>
//             {active === "partners_allianzes" && (
//               <div ref={partners_allianzesPopupRef}>
//                 <Partners_Allianzes
//                   partners_allianzes_Links={partners_allianzes_Links}
//                   setActive={setActive}
//               linkClicked={linkClicked}
//                 />
//               </div>
//             )}
//           </li> */}
//           <li className="">
//             <NavLink
//               to="/partner_allianzes/partner"
//               className="xl:py-1 py-3 px-3 inline-block nav_text  hover:border-b-2 border-[red] hover:text-[red]"
//               onClick={() => {
//                 if (active !== "contact") {
//                   setActive("contact");
//                 } else {
//                   setActive("");
//                 }
//                 linkClicked()
//               }}
//             >
//               Partners & Alliances
//             </NavLink>
//           </li>
//           {/* <NavbarMenu /> */}
//           <li className="">
//             <NavLink
//               to="/career"
//               className="xl:py-1 py-3 px-3 inline-block nav_text  hover:border-b-2 border-[red] hover:text-[red]"
//               onClick={() => {
//                 if (active !== "career") {
//                   setActive("career");
//                 } else {
//                   setActive("");
//                 }
//                 linkClicked()
//               } }
//             >
//               Career
//             </NavLink>
//           </li>
//           <li className="">
//             <NavLink
//               to="/contact"
//               className="xl:py-1 py-3 px-3 inline-block nav_text  hover:border-b-2 border-[red] hover:text-[red]"
//               onClick={() => {
//                 if (active !== "contact") {
//                   setActive("contact");
//                 } else {
//                   setActive("");
//                 }
//                 linkClicked()
//               }}
//             >
//               Contact
//             </NavLink>
//           </li>
//         </ul>
//         {/* ***********for mobile screen*********** */}
//         <ul
//           className={` xl:hidden bg-[#fff]  absolute w-full h-auto top-0 py-20 pl-4 z-40 duration-500 ${
//             open ? "left-0" : "left-[-100%] "
//           }`}
//         >
//           <li className="" onClick={() => setOpen("")}>
//             <Link to="/" className="py-3 px-3 inline-block text-base ">
//               Home{" "}
//             </Link>
//           </li>
//           <li>
//             <span
//               to="#"
//               className="py-3 px-3  text-base  xl:hidden flex justify-between items-center "
//               onClick={() => {
//                 if (smallScreenActive !== "services") {
//                   setSmallScreenActive("services");
//                 } else {
//                   setSmallScreenActive("");
//                 }
//                 setSmallScreenSubActive("");
               
//               }
              
//             }
//             >
//               Services
//               <DropdownIcon isOpen={smallScreenActive === "services"} />
//             </span>
//             <div className="xl:hidden block">
//               {smallScreenActive === "services" && (
//                 <Services
//                   servicesLinks={servicesLinks}
//                   smallScreenSubactive={smallScreenSubactive}
//                   setSmallScreenSubActive={setSmallScreenSubActive}
//                   setSmallScreenActive={setSmallScreenActive}
//                   setOpen={setOpen}
                  
//                 />
//               )}
//             </div>
//           </li>
//           <li>
//             <span
            
//               className="py-3 px-3  text-base   xl:hidden flex justify-between items-center "
//               onClick={() => {
//                 if (smallScreenActive !== "industries") {
//                   setSmallScreenActive("industries");
//                 } else {
//                   setSmallScreenActive("");
//                 }
//                 setSmallScreenSubActive("");
//               }}
//             >
//               Industries
//               <DropdownIcon isOpen={smallScreenActive === "industries"} />
//             </span>
//             <div className="xl:hidden block">
//               {smallScreenActive === "industries" && (
//                 <Industries
//                   industriesLinks={industriesLinks}
//                   smallScreenActive={smallScreenActive}
//                   setSmallScreenSubActive={setSmallScreenSubActive}
//                   setSmallScreenActive={setSmallScreenActive}
//                   setOpen={setOpen}
//                   linkClicked={linkClicked}
//                 />
//               )}
//             </div>
//           </li>
//           <li>
//             <span
           
//               className="py-3 px-3  text-base  xl:hidden flex justify-between items-center "
//               onClick={() => {
//                 if (smallScreenActive !== "insights") {
//                   setSmallScreenActive("insights");
//                 } else {
//                   setSmallScreenActive("");
//                 }
//               }}
//             >
//               Insights
//               <DropdownIcon isOpen={smallScreenActive === "insights"} />
//             </span>
//             <div className="xl:hidden block">
//               {smallScreenActive === "insights" && (
//                 <Insights
//                   insightsLinks={insightsLinks}
//                   smallScreenActive={smallScreenActive}
//                   setActive={setActive}
//                   setSmallScreenSubActive={setSmallScreenSubActive}
//                   setSmallScreenActive={setSmallScreenActive}
//                   setOpen={setOpen}
//                 />
//               )}
//             </div>
//           </li>
//           <li>
//             {/* <span
             
//               className="py-3 px-3  text-base   xl:hidden flex justify-between items-center "
//               onClick={() => {
//                 if (smallScreenActive !== "partners_allianzes") {
//                   setSmallScreenActive("partners_allianzes");
//                 } else {
//                   setSmallScreenActive("");
//                 }
//               }}
//             >
//               Partners & Allianzes
//               <DropdownIcon
//                 isOpen={smallScreenActive === "partners_allianzes"}
//               />
//             </span>
//             <div className="xl:hidden block">
//               {smallScreenActive === "partners_allianzes" && (
//                 <Partners_Allianzes
//                   partners_allianzes_Links={partners_allianzes_Links}
//                   smallScreenActive={smallScreenActive}
//                   setSmallScreenSubActive={setSmallScreenSubActive}
//                   setSmallScreenActive={setSmallScreenActive}
//                   setOpen={setOpen}
//                 />
//               )}
//             </div> */}
//           </li>
//            <li onClick={() => setOpen("")}>
//             <Link to="/partner_allianzes/partner" className="py-3 px-3 inline-block text-base  ">
//                Partners & Allianzes
//             </Link>
//           </li>
//           <li onClick={() => setOpen("")}>
//             <Link to="/career" className="py-3 px-3 inline-block text-base  ">
//               Career
//             </Link>
//           </li>
//           <li onClick={() => setOpen("")}>
//             <Link to="/contact" className="py-3 px-3 inline-block text-base  ">
//               Contact
//             </Link>
//           </li>
//           {/* <NavbarMenu /> */}
//         </ul>
//       </div>
//       {/* *************** navlinks data this is for medium screen to desktop screeen*********** */}
//       {active === "services" && (
//         <div className="relative xl:block hidden" ref={servicesPopupRef}>
//           <Services
//             servicesLinks={servicesLinks}
//             active={active}
//             setActive={setActive}
//             setSmallScreenActive={setSmallScreenActive}
//             setOpen={setOpen}
//             linkClicked={linkClicked}
//           />
//         </div>
//       )}
//       {active === "industries" && (
//         <div className="relative xl:block hidden" ref={industriesPopupRef}>
//         <Industries
//  industriesLinks={industriesLinks}
//  active={active}
//  setActive={setActive}
//  setSmallScreenActive={setSmallScreenActive}
//  setOpen={setOpen}
//  linkClicked={linkClicked}
// />

//         </div>
//       )}
//       {/* {active === "insights" && (
        
//         <div className="relative xl:block hidden">
//           <Insights insightsLinks={insightsLinks} />
//         </div>
//       )} */}
//       {/* {active === "partners_allianzes" && (
//         <div className="relative xl:block hidden">
//           <Partners_Allianzes
//             partners_allianzes_Links={partners_allianzes_Links}
//           />
//         </div>
//       )} */}
//         </nav>
    

    
//   );
// };

// export default Navbar;



// new navbar design




import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useOutletContext } from "react-router-dom";
import Kapil_Technologies_logo from "../../../Assets/Kapil Technlogies Logo.png";
import Services from "./Services";
import Industries from "./Industries";
import Insights from "./Insights";
import Partners_Allianzes from "./Partners_Allianzes";

export const DropdownIcon = ({ isOpen }) =>


  isOpen ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 15.75l7.5-7.5 7.5 7.5"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
const Navbar = ({ linkClicked }) => {
  

  

  


  const MenuIcon = ({ open }) =>
    open ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 10-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 10-3 0m3 0a1.5 1.5 0 10-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 10-3 0m3 0a1.5 1.5 0 10-3 0m-9.75 0h9.75"
        />
      </svg>
    );

  const servicesLinks = {
    enterprise_technologies: {
      name: "ENTERPRISE TECHNOLOGIES AND SERVICES",
      links: {
        subLink1: [
          // {
          //   name: "Business Counsulting",
          //   link: "/services/business-consulting",
          // },
          
          { name: "Digital", link: "/services/digital" },
          {
            name: "Oracle",
            link: "/services/oracle_and_id_edwards",
          },
          { name: "SAP", link: "/services/sap" },
          { name: "Infor", link: "/services/infor" },
          { name: " Microsoft", link: "/services/microsoft" },
          { name: "Odoo", link: "/services/odoo" },
          // { name: "PTC", link: "/services/ptc" },
          { name: "AWS", link: "/services/aws" },
          { name: "Google Cloud", link: "/services/googlecloud" },
         
        ],
        subLink2: [
         {
            name: "Customer Relationship Management",
            link: "/services/customer_relationship_management",
          },
          {
            name: " Manufacturing Execution Systems",
            link: "/services/manufacturing_execution_systems",
          },
          {
            name: "Product Lifecycle Management",
            link: "/services/product_lifecycle_management",
          },
          {
            name: "Supply Chain Management",
            link: "/services/supply_chain_management",
          },
          {
            name: "Environmental Social & Governance",
            link: "/services/environmental_social_governance",
          },
          { name: "IT Transformation", link: "/services/it_transformation" },
          {
            name: "Applications Management",
            link: "/services/applications_management",
          },
          
          { name: "Application Development", link: "/services/development" },
          { name: "Testing", link: "/services/testing" },
        ],
      },
    },
    // Services: {
    //   name: "SERVICES",
    //   links: {
    //     subLink1: [{ name: "Development", link: "/services/development" }],
    //   },
    // },
    Products: {
      name: "PRODUCTS",
      links: {
        subLink1: [
          // { name: "Kapil Technologies 360 Degrees", link: "/services/Kapil Technologies360-degrees" },
          { name: "Finsta", link: "/services/finsta" },
          // { name: "EZChit", link: "/services/easychits" },
          { name: "Hexabuild", link: "/services/hexabuilt" },

          { name: "IFCA", link: "/services/ifca" },

          {
            name: "MES (Manufacturing Execution System)",
            link: "/services/mes",
          },
        ],

        subLink2: [
          { name: "QRRA (PFA)", link: "/services/qrra" },

          { name: "MPDV", link: "/services/mpdv" },
          {
            name: "SPS ( Smart Producions Solitions)",
            link: "/services/spa",
          },
          { name: "Mobillor (PFA)", link: "/services/mobillor" },
        ],
      },
    },
  };
  const industriesLinks = {
    industries_1: {
      links: {
        subLink1: [
          { name: "Automotive", link: "/industries/automotive" },
          { name: "Construction", link: "/industries/construction" },
          { name: "Energy", link: "/industries/energy" },

          { name: "Equipment", link: "/industries/equipment" },
          { name: "Food and Beverage", link: "/industries/food_and_beverage" },
        ],
      },
    },
    industries_2: {
      links: {
        subLink1: [
          {
            name: "Industrial Machinery and Equipment",
            link: "/industries/industrial_macinery_and_equipment",
          },
          {
            name: "Industrial Manufacturing",
            link: "/industries/industrial_manufacturing",
          },
          {
            name: "Life Sciences",
            link: "/industries/life_sciences",
          },
          {
            name: "High Tech and Electronics",
            link: "/industries/high_tech_and_electrinics",
          },
        ],
      },
    },
  };

  const insightsLinks = {
    insights_1: {
      links: {
        subLink1: [
          { name: "About us", link: "/insights/aboutus" },
          // { name: "Leadership", link: "/insights" },
          // { name: "Customer Stories", link: "/insights/customerstories" },
          { name: "Webinar", link: "/insights/webinar" },
          // { name: " Blog", link: "/insights/blog" },
        ],
      },
    },
  };

  const partners_allianzes_Links = {
    partners_allianzes_1: {
      links: {
        subLink1: [
          { name: "Partners", link: "/partner_allianzes/partner" },
          { name: "Alliances", link: "/" },
        ],
      },
    },
  };
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("");
  const [smallScreenActive, setSmallScreenActive] = useState("");

  const [smallScreenSubactive, setSmallScreenSubActive] = useState("");

  const servicesPopupRef = useRef(null);
  const servicesPopupArrowRef = useRef(null);
  const industriesPopupRef = useRef(null);
  const industriesPopupArrowRef = useRef(null);
  const insightsPopupRef = useRef(null);
  const insightsPopupArrowRef = useRef(null);
  const partners_allianzesPopupRef = useRef(null);
  const partners_allianzesPopupArrowRef = useRef(null);

  const ActiveRef = useRef(active);
  useEffect(() => {
    ActiveRef.current = active;
  }, [active]);

  const handleClickOutside = (event) => {
    if (ActiveRef.current === "services") {
      if (
        servicesPopupRef.current &&
        !servicesPopupRef.current.contains(event.target) &&
        !servicesPopupArrowRef.current.contains(event.target)
      ) {
        setActive("");
      }
    } else if (ActiveRef.current === "industries") {
      if (
        industriesPopupRef.current &&
        !industriesPopupRef.current.contains(event.target) &&
        !industriesPopupArrowRef.current.contains(event.target)
      ) {
        setActive("");
      }
    } else if (ActiveRef.current === "insights") {
      if (
        insightsPopupRef.current &&
        !insightsPopupRef.current.contains(event.target) &&
        !insightsPopupArrowRef.current.contains(event.target)
      ) {
        setActive("");
      }
    } else if (ActiveRef.current === "partners_allianzes") {
      if (
        partners_allianzesPopupRef.current &&
        !partners_allianzesPopupRef.current.contains(event.target) &&
        !partners_allianzesPopupArrowRef.current.contains(event.target)
      ) {
        setActive("");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 
  return (
    <nav className="bg-[#fff] shadow-lg xl:sticky xl:top-0  xl:z-50  border-t-red-500  border-r-red-500  border-red border-t-red-500 border-4 border-l-white border-r-red border-b-white ">

         <div className="relative w-full h-1 bg-gradient-to-r from-red-500 to-red-600">
        <div className="absolute right-0 w-6 h-8 bg-red-500 transform skew-x-12"></div>
      </div>


      <div className="flex items-center font-medium justify-between xl:h-16 lg:px-10">
        <div className="z-50 p-5 xl:w-auto w-full flex justify-between ">
          <Link to="/">
            <img
              src={Kapil_Technologies_logo}
              alt="Kapil_Technologies_logo"
              className="xl:cursor-pointer h-16 py-1"
          onClick={()=>{linkClicked()}}
  
            />
          </Link>

          <div className="xl:hidden" onClick={() => setOpen(!open)}>
            <MenuIcon open={open} />
          </div>
        </div>
        {/* ***********navlinks for large screen*********** */}

        <ul className="xl:flex hidden   items-center gap-6  relative">
          {/* <li className=""> */}
          {/* <NavLink
              to="/"
              className="xl:py-1 py-3 px-3 inline-block nav_text hover:border-b-2 border-[#000]"
              onClick={() => {
                if (active !== "home") {
                  setActive("home");
                } else {
                  setActive("");
                }
              }}
            >
              Home
            </NavLink>
          </li> */}
          <li className="">
            <NavLink
       
              to="/"
              className="xl:py-1 py-3 px-3 inline-block nav_text hover:border-b-2 hover:text-[red]"
              onClick={() => {
                if (active !== "home") {
                  setActive("home");
                } else {
                  setActive("");
                }
                linkClicked()
              }}
              
               
            >
              Home
            </NavLink>
          </li>
          <li className="" ref={servicesPopupArrowRef}>
          <span
              className={`xl:py-1 py-3 px-3  nav_text hover:text-red  ${
                active === "services" ? "border-b-2 hover:border-[red] " : ""
              } xl:flex items-center gap-1 hidden cursor-pointer`}
              onClick={() => {
                if (active !== "services") {
                  setActive("services");
                } else {
                  setActive("");
                }
              }}
            >
             
              Services
              <DropdownIcon  isOpen={active === "services"} />
              </span>
           
          </li>
          <li className="" ref={industriesPopupArrowRef}>
            <span
              className={` xl:py-1 py-3 px-3  nav_text hover:text-red  ${
                active === "industries" ? "border-b-2 hover:border-[red] " : ""
              }  xl:flex items-center gap-1 hidden cursor-pointer `}
              onClick={() => {
                if (active !== "industries") {
                  setActive("industries");
                } else {
                  setActive("");
                }
              }}
            >
              Industries
              <DropdownIcon isOpen={active === "industries"} />
            </span>
          </li>
          <li className="" ref={insightsPopupArrowRef}>
            <span
              className={`xl:py-1 py-3 px-3 nav_text hover:text-red  ${
                active === "insights" ? "border-b-2 border-[#000] hover:border-red " : ""
              } xl:flex items-center gap-1 hidden cursor-pointer `}
              onClick={() => setActive(active !== "insights" ? "insights" : "")}
            >
              Insights
              <DropdownIcon isOpen={active === "insights"} />
            </span>
            {active === "insights" && (
              <div ref={insightsPopupRef}>
                <Insights insightsLinks={insightsLinks} setActive={setActive} linkClicked={linkClicked}   />
              </div>
            )}
          </li>
          {/* <li className="" ref={partners_allianzesPopupArrowRef}>
            <span
              className={`
                 xl:py-1 py-3 px-3  nav_text ${
                   active === "partners_allianzes"
                     ? "border-b-2 border-[#000]"
                     : ""
                 }  xl:flex items-center gap-1 hidden cursor-pointer `}
              onClick={() => {
                if (active !== "partners_allianzes") {
                  setActive("partners_allianzes");
                } else {
                  setActive("");
                }
              }}
            >
              Partners & Alliances
              <DropdownIcon isOpen={active === "partners_allianzes"} />
            </span>
            {active === "partners_allianzes" && (
              <div ref={partners_allianzesPopupRef}>
                <Partners_Allianzes
                  partners_allianzes_Links={partners_allianzes_Links}
                  setActive={setActive}
              linkClicked={linkClicked}
                />
              </div>
            )}
          </li> */}
          <li className="">
            <NavLink
              to="/partner_allianzes/partner"
              className="xl:py-1 py-3 px-3 inline-block nav_text  hover:border-b-2 border-[red] hover:text-[red]"
              onClick={() => {
                if (active !== "contact") {
                  setActive("contact");
                } else {
                  setActive("");
                }
                linkClicked()
              }}
            >
              Partners & Alliances
            </NavLink>
          </li>
          {/* <NavbarMenu /> */}
          <li className="">
            <NavLink
              to="/career"
              className="xl:py-1 py-3 px-3 inline-block nav_text  hover:border-b-2 border-[red] hover:text-[red]"
              onClick={() => {
                if (active !== "career") {
                  setActive("career");
                } else {
                  setActive("");
                }
                linkClicked()
              } }
            >
              Career
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/contact"
              className="xl:py-1 py-3 px-3 inline-block nav_text  hover:border-b-2 border-[red] hover:text-[red]"
              onClick={() => {
                if (active !== "contact") {
                  setActive("contact");
                } else {
                  setActive("");
                }
                linkClicked()
              }}
            >
              Contact
            </NavLink>
          </li>
        </ul>
        {/* ***********for mobile screen*********** */}
        <ul
          className={` xl:hidden bg-[#fff]  absolute w-full h-auto top-0 py-20 pl-4 z-40 duration-500 ${
            open ? "left-0" : "left-[-100%] "
          }`}
        >
          <li className="" onClick={() => setOpen("")}>
            <Link to="/" className="py-3 px-3 inline-block text-base ">
              Home{" "}
            </Link>
          </li>
          <li>
            <span
              to="#"
              className="py-3 px-3  text-base  xl:hidden flex justify-between items-center "
              onClick={() => {
                if (smallScreenActive !== "services") {
                  setSmallScreenActive("services");
                } else {
                  setSmallScreenActive("");
                }
                setSmallScreenSubActive("");
               
              }
              
            }
            >
              Services
              <DropdownIcon isOpen={smallScreenActive === "services"} />
            </span>
            <div className="xl:hidden block">
              {smallScreenActive === "services" && (
                <Services
                  servicesLinks={servicesLinks}
                  smallScreenSubactive={smallScreenSubactive}
                  setSmallScreenSubActive={setSmallScreenSubActive}
                  setSmallScreenActive={setSmallScreenActive}
                  setOpen={setOpen}
                  
                />
              )}
            </div>
          </li>
          <li>
            <span
            
              className="py-3 px-3  text-base   xl:hidden flex justify-between items-center "
              onClick={() => {
                if (smallScreenActive !== "industries") {
                  setSmallScreenActive("industries");
                } else {
                  setSmallScreenActive("");
                }
                setSmallScreenSubActive("");
              }}
            >
              Industries
              <DropdownIcon isOpen={smallScreenActive === "industries"} />
            </span>
            <div className="xl:hidden block">
              {smallScreenActive === "industries" && (
                <Industries
                  industriesLinks={industriesLinks}
                  smallScreenActive={smallScreenActive}
                  setSmallScreenSubActive={setSmallScreenSubActive}
                  setSmallScreenActive={setSmallScreenActive}
                  setOpen={setOpen}
                  linkClicked={linkClicked}
                />
              )}
            </div>
          </li>
          <li>
            <span
           
              className="py-3 px-3  text-base  xl:hidden flex justify-between items-center "
              onClick={() => {
                if (smallScreenActive !== "insights") {
                  setSmallScreenActive("insights");
                } else {
                  setSmallScreenActive("");
                }
              }}
            >
              Insights
              <DropdownIcon isOpen={smallScreenActive === "insights"} />
            </span>
            <div className="xl:hidden block">
              {smallScreenActive === "insights" && (
                <Insights
                  insightsLinks={insightsLinks}
                  smallScreenActive={smallScreenActive}
                  setActive={setActive}
                  setSmallScreenSubActive={setSmallScreenSubActive}
                  setSmallScreenActive={setSmallScreenActive}
                  setOpen={setOpen}
                />
              )}
            </div>
          </li>
          <li>
            {/* <span
             
              className="py-3 px-3  text-base   xl:hidden flex justify-between items-center "
              onClick={() => {
                if (smallScreenActive !== "partners_allianzes") {
                  setSmallScreenActive("partners_allianzes");
                } else {
                  setSmallScreenActive("");
                }
              }}
            >
              Partners & Allianzes
              <DropdownIcon
                isOpen={smallScreenActive === "partners_allianzes"}
              />
            </span>
            <div className="xl:hidden block">
              {smallScreenActive === "partners_allianzes" && (
                <Partners_Allianzes
                  partners_allianzes_Links={partners_allianzes_Links}
                  smallScreenActive={smallScreenActive}
                  setSmallScreenSubActive={setSmallScreenSubActive}
                  setSmallScreenActive={setSmallScreenActive}
                  setOpen={setOpen}
                />
              )}
            </div> */}
          </li>
           <li onClick={() => setOpen("")}>
            <Link to="/partner_allianzes/partner" className="py-3 px-3 inline-block text-base  ">
               Partners & Allianzes
            </Link>
          </li>
          <li onClick={() => setOpen("")}>
            <Link to="/career" className="py-3 px-3 inline-block text-base  ">
              Career
            </Link>
          </li>
          <li onClick={() => setOpen("")}>
            <Link to="/contact" className="py-3 px-3 inline-block text-base  ">
              Contact
            </Link>
          </li>
          {/* <NavbarMenu /> */}
        </ul>
      </div>
      {/* *************** navlinks data this is for medium screen to desktop screeen*********** */}
      {active === "services" && (
        <div className="relative xl:block hidden" ref={servicesPopupRef}>
          <Services
            servicesLinks={servicesLinks}
            active={active}
            setActive={setActive}
            setSmallScreenActive={setSmallScreenActive}
            setOpen={setOpen}
            linkClicked={linkClicked}
          />
        </div>
      )}
      {active === "industries" && (
        <div className="relative xl:block hidden" ref={industriesPopupRef}>
        <Industries
 industriesLinks={industriesLinks}
 active={active}
 setActive={setActive}
 setSmallScreenActive={setSmallScreenActive}
 setOpen={setOpen}
 linkClicked={linkClicked}
/>

        </div>
      )}
      {/* {active === "insights" && (
        
        <div className="relative xl:block hidden">
          <Insights insightsLinks={insightsLinks} />
        </div>
      )} */}
      {/* {active === "partners_allianzes" && (
        <div className="relative xl:block hidden">
          <Partners_Allianzes
            partners_allianzes_Links={partners_allianzes_Links}
          />
        </div>
      )} */}
        </nav>
    

    
  );
};

export default Navbar;
