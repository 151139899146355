
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import HighTech_innovation from '../../../Assets/High-Tech Innovation.png';
import smart_innovation from '../../../Assets/Smart Factory Solutions.png';
import Partnership from '../../../Assets/Partnerships.png';
import Regulation from '../../../Assets/Regulatory Compliance.png'

const HighTechAndElectronics = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Empowering High-Tech Innovation"
        paragragh={
          <>
            We offer cutting-edge solutions that drive innovation in the
            high-tech and electronics industries. Our expertise helps businesses
            stay ahead in a rapidly evolving digital landscape, providing them
            with the tools and technologies necessary for success.
          </>
        }
        imgScr={HighTech_innovation}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Smart Manufacturing Solutions"
        paragraph={
          <>
            Unlock the potential of Industry 4.0 with our smart manufacturing
            solutions. We integrate AI, machine learning, and IoT to create
            intelligent production lines, enabling seamless operations,
            predictive maintenance, and optimized productivity in the high-tech
            electronics space.
          </>
        }
        imgsrc={smart_innovation}
      />
      <HeadingParagraphSection
        heading="Sustainability in Electronics"
        paragraph={
          <>
            We are committed to sustainable practices in the electronics
            industry, helping businesses minimize environmental impact. Our
            eco-friendly materials, energy-efficient production techniques, and
            compliance with global sustainability standards ensure you stay
            ahead of regulations and consumer expectations.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="R&D and Innovation Partnerships"
        paragraph={
          <>
            We collaborate with industry leaders in research and development to
            drive the next generation of technological advancements. Whether
            it's in semiconductors, consumer electronics, or telecommunications,
            our R&D expertise accelerates innovation.
          </>
        }
        imgsrc={Partnership}
      />
      <Image_ContentReusableComponent
        heading="Quality Assurance and Regulatory Compliance"
        paragraph={
          <>
           <p> With increasing complexity in electronics manufacturing, we ensure
            that your products meet the highest quality standards. Our quality
            assurance frameworks and regulatory compliance solutions are
            tailored to meet industry-specific certifications and global market
            requirements.
            </p>
            Each section can be expanded based on the specific services or
            technologies your company offers in the high-tech and electronics
            industries.
          </>
        }
        imgsrc={Regulation}
      />
    </div>
  );
};

export default HighTechAndElectronics;
