export  const carrerList = [

  {
    position: "Digital Marketing Manager ",
    description:
      "We are looking for a skilled Digital Marketing Manager to lead online marketing strategies that drive traffic, enhance brand awareness, and boost engagement across digital channels. This role involves collaborating with cross-functional teams to develop and implement campaigns aligned with our company’s objectives. ",
    requirements: [
      "Bachelor’s / Masters’s degree in Marketing, Communications, Business, or a related field. ",
      "3-5 years of experience in digital marketing",
      "Strong knowledge of digital marketing platforms (Google Ads, Facebook Ads, Google Analytics, SEO tools, etc.)",
    ],
    jobType: "Full-time",
    Mode: "Onsite",
    location: "Hyderabad ,Nanakramguda",
    experience: "5-10 Years  ",
  },
  {
    position: "Infor WMS Techno-Functional Consultant",
    description:
      "This role requires expertise in warehouse management , Infor WMS modules, and integration with other enterprise systems. The individual will work closely with stakeholders to deliver solutions, troubleshoot issues, and ensure successful WMS deployments. ",
    requirements: [
      "6+ years of experience working with Infor WMS or similar warehouse management systems (SAP, Oracle, Manhattan, etc.). ",
      "Strong understanding of warehouse operations and logistics processes. ",
      "Experience in configuring, customizing, and implementing WMS systems. ",
      "Familiarity with integration methods (API, EDI, middleware, etc.) and how they interact with WMS. ",
    ],
    jobType: "Full-time",
    Mode: "Onsite",
    location: "Hyderabad ,Nanakramguda",
    experience: "6 - 10 Years ",
  },
  {
    position: "Business Development Manager ",
    description:
      "This role focuses on acquiring new clients and delivering high-value solutions in the IT industry. The BDM will work closely with internal teams, including sales, marketing, and technical experts, to understand customer needs and present tailored solutions that align with the company’s capabilities and market trends. ",
    requirements: [
      "5+ years of experience in business development or sales within the IT services industry (e.g., managed IT services, cloud computing, software development, IT consulting).  ",
      "Expertise in the entire sales cycle: prospecting, lead generation, proposal development, contract negotiation, and closing.  ",
      "Experience with CRM systems (e.g., Salesforce, HubSpot) and sales pipeline management.  ",
      
    ],
    jobType: "Full-time",
    Mode: "Onsite",
    location: "Hyderabad ,Nanakramguda",
    experience: "7 - 12 Years ",
  },
];
