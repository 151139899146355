import React, { useEffect, useState } from "react";
import careercarousel3 from "../../../Assets/careerscarousels3.png";
import careercarousel2 from "../../../Assets/careercarousel2.png";
import careercarousel1 from "../../../Assets/careercarousel1.png";
import diversity from "../../../Assets/diversity.png";

import { useScrollAnimation } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; 
import { 
  
  Briefcase, 
  MapPin, 
 
  User, 
  Zap 
} from 'lucide-react';

import {carrerList} from './CareerList'



const Career = () => {

const navigate = useNavigate();



const handleApply = (job) => {

  navigate(`/career/position_description/${job.position}`, {
    state: { job }, 
  });
};

  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = 3; 

  
  const handleSlideChange = (slideNumber) => {
    setCurrentSlide(slideNumber);
  };

 
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === totalSlides ? 1 : prevSlide + 1
      );
    }, 5000); 

    return () => clearInterval(interval); 
  }, [totalSlides]);

  useScrollAnimation();


  return (
    <div className="bg-[#fff]">
    {/* <h2 className="text-center text-3xl pb-6 animate-up">
    "Join Us and Let Your Happy Face Light Up Our Gallery!"
  </h2> */}
      {/* <section className="bg-gray-100 ">
      <div className="container mx-auto ">
        <div className="flex items-center justify-center">
          <div className=" w-full">
            <div className="bg-white overflow-hidden">
              <img
                src="https://ik.imagekit.io/7wqj9br0b/Carrer%20Page/Carrer%20Intro%201.jpg?updatedAt=1737694260077"
                alt="Kapil Careers"
                className="w-full h-auto max-h-[450px] object-cover object-center "
              />
            </div>
          </div>
        </div>
      </div>
    </section> */}

      {/* <section className="py-10">
        <h2 className="text-center pb-10 animate-up">Meet the Challengers</h2>
        <div
          id="default-carousel"
          className="relative flex justify-center"
          data-carousel="static"
        >
      
          <div
            className={`duration-700 ease-in-out inset-0 transition-transform transform xl:mx-36 mx-3 md:mx-10 ${
              currentSlide === 1 ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ display: currentSlide === 1 ? "block" : "none" }}
          >
            <img
              src={careercarousel3}
              alt="Slide 1"
              className="w-full h-auto object-cover"
            />
          </div>

         
          <div
            className={`duration-700 ease-in-out inset-0 transition-transform transform xl:mx-36 mx-3 md:mx-10 ${
              currentSlide === 2 ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ display: currentSlide === 2 ? "block" : "none" }}
          >
            <img
              src={careercarousel1}
              alt="Slide 2"
              className="w-full h-auto object-cover"
            />
          </div>

       
          <div
            className={`duration-700 ease-in-out inset-0 transition-transform transform xl:mx-36 mx-3 md:mx-10 ${
              currentSlide === 3 ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ display: currentSlide === 3 ? "block" : "none" }}
          >
            <img
              src={careercarousel2}
              alt="Slide 3"
              className="w-full h-auto object-cover"
            />
          </div>

         
          <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
            {[1, 2, 3].map((slide) => (
              <button
                key={slide}
                type="button"
                className={`w-3 h-3 rounded-full ${
                  currentSlide === slide ? "bg-white" : "bg-black"
                }`}
                aria-current={currentSlide === slide}
                aria-label={`Slide ${slide}`}
                onClick={() => handleSlideChange(slide)}
              ></button>
            ))}
          </div>
        </div>
      </section> */}

  {/* <section className="py-10 bg-[#f5f5f5]">
  <h2 className="text-center text-3xl pb-6 animate-up">
    "Join Us and Let Your Happy Face Light Up Our Gallery!"
  </h2>
  <div className="xl:mx-40 mx-3 md:mx-10 animate-up">
    <h3 className="text-2xl pb-2 font-semibold">Multiple positions</h3>
    <hr className="border-[#cbd5e1] pb-6" />
  </div>
  {careerlist.map((items) => (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 xl:mx-40 mx-3 md:mx-10">
      <div className="col-span-1 animate-up mb-6 flex flex-col justify-between">
        <div>
          <h4 className="font-normal text-xl pb-1">{items.position}</h4>
          <p>
            <span className="text-bluecolor text-lg">Job Duties: </span>
            {items.description}
          </p>
        </div>
        <hr className="border-[#cbd5e1] " />
      </div>
      <div className="col-span-1 animate-up mb-6 flex flex-col justify-between">
        <div>
          <p>
            <span className="text-bluecolor text-lg">Requirements: </span>
            {items.requirements}
          </p>
        </div>
        <div>
          <Link to="position_description">
            <button className="border border-[#fff] rounded-md px-3 py-2 mb-4 text-[#fff] bg-bluecolor hover:bg-darkblue">
            Apply Now
            </button>
          </Link>
        </div>
        <hr className="border-[#cbd5e1] " />
      </div>
    </div>
  ))}
</section>
   */}
      
{/* carrer section-2 */}
     {/* <div className="relative h-[400px] overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-90"></div>
        <img
          src="https://ik.imagekit.io/7wqj9br0b/Carrer%20Page/Carrer%20Intro%201.jpg?updatedAt=1737694260077"
          alt="Career Banner"
          className="w-full h-full object-cover opacity-20 "
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white space-y-4">
            <h1 className="text-3xl text-[#6A0DAD]">Build Your Future with Us!</h1>
            <p className="text-lg  text-justify text-[black]">We believe in collaboration, innovation, and growth. Join our team of forward-thinkers and let's make big things happen</p>
          </div>
        </div>
      </div> */}

    

    <div className="relative h-48 sm:h-64 md:h-80 lg:h-[400px] w-full overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-90 z-10"></div>
      
      {/* Background image */}
      <img
        src="https://ik.imagekit.io/7wqj9br0b/Carrer%20Page/Carrer%20Intro%201.jpg?updatedAt=1737694260077"
        alt="Career Banner"
        className="w-full h-full object-cover opacity-50"
      />
      
      {/* Content container */}
      <div className="absolute inset-0 flex items-center justify-center z-20">
        <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
          <div className="text-center space-y-2 sm:space-y-4">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#6A0DAD]">
              Build Your Future with Us!
            </h1>
            {/* <p className="text-sm  sm:text-base md:text-lg text-black max-w-2xl mx-auto">
              We believe in collaboration, innovation, and growth. Join our team of forward-thinkers and let's make big things happen
            </p> */}
            </div>
            
        </div>
        </div>
        
    </div>

      <section className="py-12 bg-gradient-to-r from-[#f7f8f9] to-[#ffffff] w-full">
         
      <div className=" mx-auto px-4">
       

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {carrerList.map((job, index) => (
            
            <div
  key={index}
  className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
>
  <div className="p-6 max-w-md mx-auto"> {/* Added max-width for better control */}
    <div className="flex items-center mb-4">
      <h3 className="ml-4 text-2xl font-semibold text-[#000080]">
        {job.position}
      </h3>
    </div>

    <div className="space-y-3 mb-4">
      <div className="flex items-center text-gray-600">
        <Briefcase className="mr-2 text-[#4169E1] w-5 h-5" />
        <span>{job.jobType}</span>
      </div>
      <div className="flex items-center text-gray-600">
        <MapPin className="mr-2 text-[#4169E1] w-5 h-5" />
        <span>{job.location}</span>
      </div>
      <div className="flex items-center text-gray-600">
        <User className="mr-2 text-[#4169E1] w-5 h-5" />
        <span>{job.experience}</span>
      </div>
    </div>

    <p className="text-md text-gray-800 mb-3 text-[#1E3A8A]">Job Description:</p>

    <p style={{ overflowWrap: 'normal', whiteSpace: 'normal', wordBreak: 'break-word' }} className="text-gray-700 text-md">
      {job.description}
    </p>

    <button
      onClick={() => handleApply(job)}
      className="mt-5 w-full bg-blue-600 bg-black text-white hover:text-white hover:bg-red py-3 rounded-lg flex items-center justify-center"
    >
      <Zap className="mr-2 w-5 h-5" />
      Apply Now
    </button>
  </div>
</div>

          ))}
        </div>
          
          
      </div>
    </section>
      
{/* <section className="py-12 bg-[#f5f5f5]">
  <h2 className="text-center text-3xl pb-6 font-semibold text-gray-800 animate-fade-in-up">
    "Join Us and Let Your Happy Face Light Up Our Gallery!"
  </h2>
  <div className="xl:mx-40 mx-6 md:mx-12 animate-fade-in-up">
    <h3 className="text-2xl pb-4 font-semibold text-gray-700">Multiple Positions</h3>
    <hr className="border-[#cbd5e1] mb-6" />
  </div>
  
 
  {careerList.map((job, index) => (
    <div key={index} className="grid grid-cols-1 lg:grid-cols-2 gap-7 xl:mx-40 mx-3 md:mx-10">
     
      <div className="col-span-1 animate-fade-in-up mb-6 flex flex-col justify-between bg-gray p-6 rounded-lg  transition duration-300">
        <div>
          <h4 className="font-semibold text-xl text-gray-800 pb-2">{item.position}</h4>
          <p className="text-gray-600">
            <span className="text-blue-600 text-lg">Job Duties: </span>
            {item.description}
          </p>
        </div>
        <hr className="border-[#cbd5e1] my-4" />
      </div>
      
     
      <div className="col-span-1 animate-fade-in-up mb-6 flex flex-col justify-between bg-gray p-6 rounded-lg  ">
        <div>
          <p className="text-gray-600">
            <span className="text-blue-600 text-lg">Requirements: </span>
            {item.requirements}
          </p>
        </div>
        
       
        <div className="mt-4">
          <Link to="position_description">
            <button className="w-full py-3 px-6 bg-blue-600 text-black rounded-md text-lg font-semibold transition duration-300 hover:bg-blue-700 shadow-md hover:scale-105">
              Apply Now
            </button>
          </Link>
        </div>
        
        <hr className="border-[#cbd5e1] mt-6" />
      </div>
    </div>
  ))}
</section> */}


      
      {/* <section className=" py-10">
        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-10">
          <div>
            <h3 className="text-black pb-7 animate-up">
              Harmonizing Power of AI & Human Centricity
            </h3>
            <div class="video-container">
              <iframe
                width="520"
                height="315"
                src="https://www.youtube.com/embed/UMPXiH-eBXc?si=zvkkks-DHldNKpkB"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div>
            <h3 className="text-black pb-7 animate-up">
              Career Transformation – Neha Aggarwal
            </h3>
            <div class="video-container">
              <iframe
                width="520"
                height="315"
                src="https://www.youtube.com/embed/XZyEjhlJZgo?si=IEZpCZrN8gzXMENl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section> */}
      <section className="bg-darkblue py-10">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-7 xl:mx-40 mx-3 md:mx-10">
          {/* Card 1 */}
          <div className="flex flex-col justify-between max-w-sm bg-[#092f4f] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="px-5 pt-5 flex-grow">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-[#ffd626] dark:text-white animate-up">
                Diversity, Equity and Inclusion Initiative - Kapil
              </h5>
              <p className="mb-3 font-normal text-white dark:text-gray-400 animate-up">
                In an endeavor to become a workplace of choice among people, we
                are striving to create an atmosphere where multiple voices are
                heard, and their opinions are valued and considered..
              </p>
            </div>
          
              <img
                className="rounded-b-lg w-full h-72 object-cover"
                src={diversity}
                alt="diversity"
              />
           
          </div>

          {/* Card 2 */}
          <div className="flex flex-col justify-between max-w-sm bg-[#092f4f] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="px-5 pt-5 flex-grow">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-[#ffd626] dark:text-white animate-up">
                Balancing the Gender Mix
              </h5>
              <p className="mb-3 font-normal text-white dark:text-gray-400 animate-up">
                Referral Recruitment Drive – Exclusively for women to upskill or
                restart their career!
              </p>
            </div>
     
              <img
                className="rounded-b-lg w-full h-72 object-cover"
                src={diversity}
                alt="diversity"
              />
        
          </div>

          {/* Card 3 */}
          <div className="flex flex-col justify-between max-w-sm bg-[#092f4f] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="px-5 pt-5 flex-grow">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-[#ffd626] dark:text-white animate-up">
               Community Outreach and Support - Kapil
              </h5>
              <p className="mb-3 font-normal text-white dark:text-gray-400 animate-up">
                Kapil is committed to making a positive impact beyond our workplace. We actively engage in community outreach initiatives that support underrepresented groups
              </p>
            </div>
           
              <img
                className="rounded-b-lg w-full h-72 object-cover"
                src={diversity}
                alt="diversity"
              />
            
          </div>
        </div>
      </section>
      {/* <section className="py-10 bg-[#f5f5f5]">
        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7 xl:mx-40 mx-3 md:mx-10 ">
          <div className="col-span-2 px-4 animate-up">
            <h2 className="pb-5">Equal Opportunity Employer</h2>
            <p>
              As an Equal Opportunity Employer, Kapil Technologies intends to have a
              workforce reflective of the community (gender, cultural
              backgrounds, PwD, veterans) While we consciously strive for a
              gender-balanced hiring from campuses, through our capABLE program
              we also invite persons with disability to connect with us for
              career opportunities. Our Campus is designed to provide facilities
              which are disability friendly and a career canvas which aims to
              provide equal opportunities to everyone.
            </p>
            <p>
              Through our exCITE program, we engage with business schools to
              hire veterans who bring in domain experiences to enrich
              Kapil Technologies’s capabilities.
            </p>
            <p>Why hire for Diversity?</p>
            <ul>
              <li>1. Bigger talent pool to draw from</li>
              <li>2. Increased work ethic/dedication</li>
              <li>
                3. Positively impacts the retention of existing employees and
                boosts employee morale
              </li>
            </ul>
            <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
              Read our policy documents
            </button>
          </div>
          <div className="col-span-1 animate-up">
            <h2 className="pb-5">Great People To Work With</h2>
            <p>
              Our people are our biggest assets and we believe in leaving no
              stone unturned in assuring their wellness at office, as well as at
              home. Our meritocratic culture and processes of regular and timely
              feedback is designed to ensure a warm and supportive environment
              for our people to work in. We encourage our people to volunteer
              actively for cultural events, celebrations, community service
              initiatives and fun at work. This allows them to become better
              employees, better team workers and even better human beings.
            </p>
          </div>
        </div>
      </section> */}

      {/* <section className="py-10 xl:mx-60 mx-3 md:mx-10">
        <figure className="glassdoor_career flex items-center justify-center lg:justify-start p-4 lg:p-10 bg-cover bg-center bg-no-repeat rounded-lg shadow-md">
          <div className="text-center lg:text-left max-w-md mx-auto lg:mx-0">
            <p className="text-lg text-[#4d4d4f] mb-4 animate-up">
              Making Societies More Productive By Helping Our Customers Run
              Their Businesses.
            </p>
            <h2 className="pb-4 text-black text-2xl animate-up">
              We are on Glassdoor
            </h2>
            <button className="rounded-md px-4 py-2 text-white bg-bluecolor hover:bg-darkblue animate-up">
              Know more
            </button>
          </div>
        </figure>
      </section> */}

     
    </div>
  );
};

export default Career;
