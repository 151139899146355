import { useEffect, useState } from "react";
import { Link, NavLink, useOutletContext } from "react-router-dom";
import WareHouse from "../../../Assets/WareHouse (2).png";
import Data_migration from "../../../Assets/Data_migration.png";
import Incident_mangemenet from "../../../Assets/Incident_mangement.png";
import ERP_tool from "../../../Assets/ERP_tool.png";
import It_services from "../../../Assets/IT_Servicesss.png";
import home_voiceofClient from "../../../Assets/home_voiceofClient.png";
import home_excellence from "../../../Assets/home_excellence.png";
import home_digitalTransform from "../../../Assets/Home_digitalTransformation (2).png";
import "./Home.css";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import contemparty_digital from "../../../Assets/Contempary_Digital.png";
import services from "../../../Assets/Services.gif";
import Solutions from "../../../Assets/Solutionss.gif";
import Insights from "../../../Assets/Insightsss.gif";
import TitleUpdater from "../../../utils/TitleUpdater";


const Home = () => {
  const { linkClicked } = useOutletContext();

  const [currentSlide, setCurrentSlide] = useState(1);
  const slidesCount = 3;

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slidesCount ? 1 : prev + 1));
  };

  const handlePrevious = () => {
    setCurrentSlide((prev) => (prev === 1 ? slidesCount : prev - 1));
  };

  const slides = [
    {
      imgSrc: It_services,
      title:"Transforming your Digital Future",
      description:"We combine technology with innovation, creativity, and strategy to solve complex business problems and help you go beyond regular transactions and create memorable experiences.",
      linkText: "Read More",
    },
    {
      imgSrc: home_voiceofClient,
      title: "Your feedback fuels our creativity ",
      description:
        "Providing insights to accelerate tangible and trusted outcomes",
      linkText: "Read the insights",
    },
    {
      imgSrc: home_excellence,
      title: "Recognized for excellence",
      description:
        "Our latest recognition reflects our commitment to exceeding stakeholder expectations.",
      linkText: "Learn more",
    },
  ];

  return (
    <>
      <div className="view">
        <TitleUpdater title="Kapil Tech: Infor Consulting and ERP Implementation Services" />
        
   
      {/*   ****** Carousel Part******* */}
      <section className="mx-auto bg-[#efefef]">
        <div id="default-carousel" className="relative" data-carousel="static">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`duration-700 ease-in-out transition-transform transform xl:mx-36 mx-3 md:mx-10 ${
                index + 1 === currentSlide
                  ? "translate-x-0"
                  : "translate-x-full"
              }`}
              style={{ display: index + 1 === currentSlide ? "block" : "none" }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="col-span-1 flex items-center justify-center order-1 md:order-2 image_design">
                  <img
                    src={slide.imgSrc}
                    alt={slide.title}
                    className="w-full h-full"
                  />
                </div>
                <div className="col-span-1 my-auto md:px-7 order-2 md:order-1">
                  <h1 className="mt-7 font-semibold" style={{color:"#141485"}} >{slide.title}</h1>
                  <p className="text-md">{slide.description}</p>
                  <p className="my-9 flex items-center gap-1 group hover:text-darkblue">
                    <span className="transition-colors duration-300 group-hover:text-blue-500 lg:text-base">
                      {/* {slide.linkText} */}
                    </span>
                    
                  </p>
                </div>
              </div>
            </div>
          ))}

          {/* Previous Button */}
          <button
            type="button"
            className="absolute top-48 md:top-1/2 left-4 transform -translate-y-1/2 group focus:outline-none"
            onClick={handlePrevious}
            data-carousel-prev="true"
            aria-label="Previous slide"
          >
            <span className="flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-lg">
              <svg
                className="w-5 h-5 text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </span>
          </button>

          {/* Next Button */}
          <button
            type="button"
            className="absolute top-48 md:top-1/2 right-4 transform -translate-y-1/2 group focus:outline-none"
            onClick={handleNext}
            data-carousel-next="true"
            aria-label="Next slide"
          >
            <span className="flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-lg">
              <svg
                className="w-5 h-5 text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </span>
          </button>
        </div>
      </section>

      {/* insights , services, solutions */}
      <section className="bg-[#fff] py-10 mb-10">
        <div className="flex flex-col lg:flex-row gap-4 xl:mx-36 mx-3 md:mx-10">
          {[
            {
              title: "Enterprise Technologies",
              videoSrc: Solutions,
              description:
                "Our intellectual property-based solutions help you accelerate innovation and drive operational excellence.",
              tags: [
                { name: "Digital", link: "/services/digital" },
                { name: "Oracle", link: "/services/oracle_and_id_edwards" },
                { name: "SAP", link: "/services/sap" },
                { name: "Infor", link: "/services/infor" },
                { name: "Microsoft", link: "/services/microsoft" },
                { name: "Odoo", link: "/services/odoo" },
                { name: "AWS", link: "/services/aws" },
                { name: "Google Cloud", link: "/services/googlecloud" },
              ],
              exploreText: "Explore end-to-end services",
            },
            {
              title: "Services",
              videoSrc: services,
              description:
                "We help you meet the ever-evolving digital expectations of your customers and citizens through our secure and flexible services.",
              tags: [{ name: "Application Development", link: "/services/development" },
                 { name: "SCM", link: "/services/supply_chain_management" },
                { name: "CRM", link: "/services/customer_relationship_management" },
                 { name: "MES", link: "/services/manufacturing_execution_systems" },
                { name: "PLM", link: "/services/product_lifecycle_management" },
                { name: "Testing", link: "/services/testing" },
              ],
              exploreText: "Explore end-to-end services",
            },
            {
              title: "Products",
              videoSrc: Insights,
              description:
                "We provide a global antenna based on facts, not hype, to help improve IT and business services to our customers.",
              tags: [
                { name: "Finsta", link: "/services/finsta" },
                { name: "EZChit", link: "/services/easychits" },
                { name: "Hexabuild", link: "/services/Kapil Technologies360-degrees" },
                { name: "IFCA", link: "/services/ifca" },
                {
                  name: "MES ",
                  link: "/services/mes",
                },
              ],
              exploreText: "Explore all insights",
            },
          ].map((section, idx) => (
            <div
              key={idx}
              className="flex-1 lg:border-r border-r-gray-400 px-4 animate-up flex flex-col h-full items-start"
            >
              {/* Title and Image */}
              <div className="flex items-center mb-4">
                <img
                  src={section.videoSrc}
                  alt={section.title}
                  className="mr-3 w-14 h-14"
                />
                <h2 className="text-lg font-semibold">{section.title}</h2>
              </div>

              {/* Description */}
              <p className="flex-grow mb-4">{section.description}</p>

              {/* Tags */}
              <div className="py-1 flex-grow flex items-end">
                <div>
                  {section.tags.map((tag, tagIdx) => (
                    <NavLink
                      key={tagIdx}
                      to={tag.link}
                      onClick={() => linkClicked()} //if u want to redirect a page need to start from starting page use this method
                    >
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-[#151515] mr-2 mb-2 border border-[#bbb] hover:bg-bluecolor hover:text-[#fff]">
                        {tag.name}
                      </span>
                    </NavLink>
                  ))}
                </div>
              </div>

              {/* Explore Text */}
              {/* <p className="my-3 flex items-center gap-1 group hover:text-darkblue">
                <span className="transition-colors duration-300 group-hover:text-blue-500 text-[16px] font-semibold leading-[1.5]">
                  {section.exploreText}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </p> */}
            </div>
          ))}
        </div>
      </section>

      {/*  case Studies */}
     
  <section className="py-16 bg-gray-900">
  <h1 className="text-3xl font-semibold text-black ps-10 xl:ps-24 mb-10">
    Case Studies
  </h1>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 xl:mx-10 mx-3 md:mx-10">
    {/* Card 1 */}
    <div className="bg-white  rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={Incident_mangemenet}
        alt="Incident Management"
        className="w-full h-48 object-contain"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Managed services in Infor enterprise solutions
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          An industrial equipment manufacturer in GCC region has put full trust
          on Kapil Tech for Managed services in Infor enterprise solutions
          consecutive third year. Even complex Incidents are resolved in record
          2 hours timeline.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>

    {/* Card 2 */}
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={ERP_tool}
        alt="ERP Tool"
        className="w-full h-48 object-cover"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Process integration with Infor and third-party solutions
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          Extensions are built in the standard ERP feature pack for process
          integration with both Infor and third-party solutions. Same
          extensions and integration components worked even after solution
          upgrades.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>

    {/* Card 3 */}
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={Data_migration}
        alt="Data Migration"
        className="w-full h-48 object-cover"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Migration and retrofitting around 10 extended functionalities
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          Successful migration and retrofitting around 10 extended
          functionalities from older version to a later version of Infor ERP
          for an Indian enterprise of exclusive business scenarios.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>

    {/* Card 4 */}
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col">
      <img
        src={WareHouse}
        alt="Warehouse Management"
        className="w-full h-48 object-cover"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">
          Warehouse management and Supplier collaboration
        </h3>
        <p className="text-sm text-gray-600 flex-grow">
          Edge solutions for Warehouse management and Supplier collaboration
          are deployed with custom BOD integrations. Fulfilling Customer
          objective did lead to longer association for support services.
        </p>
        <Link
          className="mt-4 text-blue-600 hover:underline flex items-center gap-1"
          to="casestudies"
          onClick={() => linkClicked()}
        >
          Learn More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>
    </div>
  </div>
        </section>
        
        

      
      <Image_ContentReusableComponent
        heading={
          " Powering Your Business Transformation With Tomorrow’s Digital Solutions"
        }
        paragraph={
          "In the age of fast-paced evolution of technology, businesses need to be at the top of their game to stay ahead of the curve. As a specialized technology and consulting services provider, Kapil Technologies guides enterprises and IT service providers to achieve transformational business outcomes through exceptional-quality products and services for their customers. Our consulting services give enterprises the operational edge by empowering them with easy and effective decision-making abilities."
        }
        imgsrc={home_digitalTransform}
      />
      <Image_ContentReusableComponent
        reverse={false}
        bgColor="#fff"
        heading={
          " Reimagine the Possibilities and Conquer New Horizons with Kapil Technologies’ Contemporary Digital Tech Capabilities"
        }
        paragraph={
          "Helping your business thrive in this digital age is all about adapting to the constantly evolving technology landscape. With  its next-generation tech capabilities, Kapil Technologies has the resourcefulness to help you reinvent your tech strategies and transform your business processes for a performance-driven growth. Trust our consulting services to provide you with custom tech solutions that help you realize tangible results."
        }
        imgsrc={contemparty_digital}
      />
      </div>

      
      </>
  );
};

export default Home;
