import React, { useState } from 'react';
import { HiMail, HiPhone, HiUser, HiOfficeBuilding } from 'react-icons/hi';
import { HiPaperAirplane } from "react-icons/hi2";
import { IoEarthOutline } from 'react-icons/io5';
import countries from '../../../../utils/COUNTRIESLIST';
import { toast } from 'react-toastify';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    country: '',
    message: '',
    marketing: false
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ show: false, success: false, message: '' });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
    
  //   try {
  //     await new Promise(resolve => setTimeout(resolve, 1000));
  //     setSubmitStatus({
  //       show: true,
  //       success: true,
  //       message: 'Thank you for your message. We will get back to you soon!'
  //     });
  //     resetForm();
  //   } catch (error) {
  //     setSubmitStatus({
  //       show: true,
  //       success: false,
  //       message: 'Something went wrong. Please try again.'
  //     });
  //   } finally {
  //     setIsSubmitting(false);
  //     setTimeout(() => setSubmitStatus({ show: false, success: false, message: '' }), 5000);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://kapil-api-backend.onrender.com/api/infor/InforContactForm ', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.success) {
        toast.success('We will get back to you soon!');
        resetForm();
      } else {
        toast.error(data.message || 'Something went wrong. Please try again.');
      }
      
    } catch (error) {
      console.error('Error in form submission:', error);
      toast.error('Something went wrong. Please try again.');
      
    } finally {
      setIsSubmitting(false);
    }
  };
  const resetForm = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      company: '',
      country: '',
      message: '',
      marketing: false
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-red mb-4">Get in Touch</h1>
          <p className="text-gray-600 m-auto text-center">We're Here to Help - Reach Out Anytime!</p>
        </div>

        {submitStatus.show && (
          <div className={`mb-6 p-4 rounded-lg ${submitStatus.success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'}`}>
            {submitStatus.message}
          </div>
        )}

        <form onSubmit={handleSubmit} className="bg-white shadow-xl rounded-2xl p-8 backdrop-blur-sm bg-opacity-90">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Name Field */}
            <InputField 
              label=" Name *"
              name="name"
              type="text"
              required
              value={formData.name}
              onChange={handleChange}
              icon={<HiUser />}
            />

            {/* Phone Field */}
            <InputField 
              label="Phone Number"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              icon={<HiPhone />}
            />

            {/* Email Field */}
            <InputField 
              label="Email Address *"
              name="email"
              type="email"
              required
              value={formData.email}
              onChange={handleChange}
              icon={<HiMail />}
            />

            {/* Company Field */}
            <InputField 
              label="Company *"
              name="company"
              type="text"
              required
              value={formData.company}
              onChange={handleChange}
              icon={<HiOfficeBuilding />}
            />

            {/* Country Field */}
            <SelectField 
              label="Country *"
              name="country"
              required
              value={formData.country}
              onChange={handleChange}
              options={countries}
              icon={<IoEarthOutline />}
            />

            {/* Message Field */}
            <TextAreaField 
              label="Your Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />

            {/* Marketing Consent Checkbox */}
            <CheckboxField 
              name="marketing"
              checked={formData.marketing}
              onChange={handleChange}
            />
          </div>

          {/* Submit Button */}
          <div className="mt-8 flex justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`group relative inline-flex items-center px-8 py-3 bg-blue-600 text-white bg-red hover:text-red hover:bg-white border border-transparent hover:border-red-500 font-medium transition-colors ${isSubmitting ? 'opacity-70' : ''}`}
            >
              <span className="mr-2">{isSubmitting ? 'Sending...' : 'Send Message'}</span>
              <HiPaperAirplane className={`h-5 w-5 transition-transform duration-300 ${isSubmitting ? 'translate-x-1' : ''}`} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const InputField = ({ label, name, type, required, value, onChange, icon }) => (
  <div className="relative">
    <label className="text-gray-700 mb-2 block font-medium">{label}</label>
    <div className="relative">
      {icon && <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">{icon}</span>}
      <input
        type={type}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors"
        placeholder={`Enter your ${label.toLowerCase()}`}
      />
    </div>
  </div>
);

const SelectField = ({ label, name, required, value, onChange, options, icon }) => (
  <div className="relative md:col-span-2">
    <label className="text-gray-700 mb-2 block font-medium">{label}</label>
    <div className="relative">
      {icon && <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">{icon}</span>}
      <select
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-white transition-colors appearance-none"
      >
        <option value="">Select your country</option>
        {options.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
    </div>
  </div>
);

const TextAreaField = ({ label, name, value, onChange }) => (
  <div className="relative md:col-span-2">
    <label className="text-gray-700 mb-2 block font-medium">{label}</label>
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      rows="4"
      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-colors"
      placeholder={`Type your ${label.toLowerCase()} here...`}
    />
  </div>
);

const CheckboxField = ({ name, checked, onChange }) => (
  <div className="relative md:col-span-2">
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="rounded border-gray-300 text-blue-600 focus:ring-blue w-4 h-4"
      />
      <span className="text-sm text-gray"style={{color:"gray"}}>By submitting this form, you acknowledge that we may use your personal information for marketing purposes</span>
    </label>
  </div>
);

export default ContactForm;
